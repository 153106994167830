import React, { Component, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import {getCookie} from '../util/cookies.js';

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);
const jwtValue = getCookie('loginToken');
class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: "" };
    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
    
  } 

 
  handleChange(html) {
    this.setState({ editorHtml: html });
    this.props.onEditorChange(html); // 값 변경 시 부모 컴포넌트로 전달
   
  }
  
  handleSubmit() {
    const editor = this.reactQuillRef.getEditor();
    this.setState({
      editorHtml: editor
    });
  }
  modules = {
    // #3 Add "image" to the toolbar
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      ["link", "image"],
      ["clean"]
    ],
    // # 4 Add module and upload function
    imageUploader: {
      
      upload: (file) => {
        
        return new Promise((resolve, reject) => {
          //console.log('에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!',file);
          if(file){
                const formData = new FormData();
                // multer에 맞는 형식으로 데이터 만들어준다.
                formData.append("folderName", "payback");
                //console.log(this.props.initState)
                formData.append("images", file);
                // 백엔드 multer라우터에 이미지를 보낸다.
                fetch(
                  "https://yuram2.luvmom2020.com/api/upload",
                  {
                    method: "POST",
                    body: formData,
                    headers: {
                      'Authorization': `Bearer ${jwtValue}`
                    }
                  }
                )
                  .then((response) => response.json())
                  .then((result) => {
                    if(result){
                      //console.log("result",result);
                      resolve(result[0])
                    }
                  })
                  .catch((error) => {
                    reject("Upload failed");
                    console.error("Error:", error);
                  });
          }
        });
      }
    }
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot" // #5 Optinal if using custom formats
  ];

  render() {
    return (
      <>

        <ReactQuill
          onChange={this.handleChange}
          theme="snow"
          style={{
            minHeight: "25vh"
          }}
          modules={this.modules}
          formats={this.formats}
          value={this.state.editorHtml}
          folder={this.state.folder}
        />
      </>
    );
  }
}

export default Editor;
