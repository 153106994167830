//회원가입
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {getCookie} from '../../util/cookies.js';

import "../../static/css/no_city_member_select_city.css";
import "../../static/css/selectPlace.css";
import { scrollToTop } from '../Common/TotheTop.js';

import ListLocation from "../../pages/ListLocation2"

import arrow from "../../static/img/arrow.svg";
function WhereSelect({isPopupOpen,setPopupOpen,selectedCity,setSelectedCity,setCity}) {
  const jwtValue = getCookie('loginToken');
  const [items, setItems] = useState([]);

  const handleCityClick = (city) => {
        setSelectedCity(city);
        setCity(city); //수원/서울 등 지역설정하는것임
        setPopupOpen(false);
        localStorage.setItem('selectedCity', city);
    };

    function formatDate(dateString) {
      const startDate = new Date(dateString);
      const options = { month: '2-digit', day: '2-digit', weekday: 'short' };
    
      const formattedDate = startDate.toLocaleString('ko-KR', options)
        .replace(/,/, ''); // 쉼표 제거
    
      return formattedDate;
    }
    useEffect(() => {
      scrollToTop();

      fetch('https://yuram2.luvmom2020.com/api/fairlist/list', {
                headers: {
                    'Authorization': `Bearer ${jwtValue}`
                }
            })
      .then((response) => response.json())
      .then((result) => {
          console.log(result)
          setItems(result)
      })
      .catch((error) => {
          console.error('데이터 가져오기 실패:', error);
      });
    },[]);
    const handleLinkClick = (element) => {
      setCity(element);
      localStorage.setItem('selectedCity', element); //선택한 상태로 변경
    }
 
    return (
     <>
       <div className='select_city_wrapper'>
       <div className='tit'
        style={{'color': "white",'textAlign': "center",'fontSize': "30px",'padding': "20px 0px 30px 0",
        'boxSizing': "borderBox", 'overflow': "hidden"}}>사전등록할<br/>지역을선택해주세요</div>

        <div className="WhereSelect">
            <div className='select_place_cont'>
            <ul>
              {items && items.map((item, index) => (
                <ListLocation
                key={index}
                index={index+1}
                handleLinkClick={handleLinkClick}
                code={item.code}
                name={item.name}
                startDate={formatDate(item.startDate)}
                endDate={formatDate(item.endDate)}
                />
              ))}
            </ul>
            </div>
          </div>
       </div>
     </>
    );
}

export default WhereSelect;