//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactQuill from "react-quill";
import Editor from "./editor.js";

import "../static/css/common.css";
import "../static/css/payBack.css";

import noimg from "../static/img/Introduce/introduce_back.png";
import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';

//공통
import stamp_tour from "../static/img/main/stamp_tour.png";
import preg_present from "../static/img/main/preg_present.png";
import {getCookie} from '../util/cookies.js';

function PayBack() {
  const isUserLoggedIn = localStorage.getItem('loggedIn');
    const jwtValue = getCookie('loginToken');
  const navigator = useNavigate();
  const [setting, setSetting] = useState(false);
  const [city, setCity] = useState(localStorage.getItem('selectedCity'));
  const navi_name = "베세페 이벤트";
  const navi_name_detail = "유람 이벤트/페이백안내";
  //console.log(jwtValue)
  //초기값
    const [folder, setFolder] = useState("payback");

    const [title, setTitle] = useState("");
    const [content, setcontent] = useState("");
    const [thumbnailUrl, setThumbnailUrl] = useState("");
    const [author, setAuthor] = useState("메쎄유람");
    const [createdAt, setCreatedAt] = useState();
    const [payList, setPayList] = useState([]);

    function onSetting(){
      setSetting(true)
      if (!setting) {
        setSetting(true)
      }else{
        setSetting(false)
      }
    }
    const handleEditorChange = (html) => {
      // 여기서 전달된 값(html)을 사용할 수 있습니다.
      setcontent(html);
    };
    
    useEffect(() => {
      scrollToTop();
      //생성일 보내기
      function toLocalISOString(date) {
        const off = date.getTimezoneOffset();
        const absoff = Math.abs(off);
        return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
      }
      const date = new Date();
      const formatedate = toLocalISOString(date);
      setCreatedAt(formatedate)
      fetch(`https://yuram2.luvmom2020.com/api/payback-events/${city}`, {
        method: 'GET',
      })
      .then(response => response.json())
      .then(data => {
        if(data && data.length > 0){
          setPayList(data)
        }
      })
      .catch(error => console.error('에러 발생:', error));
    },[]);


    function handleSubmit(){

      if (!title || !content ) {
        alert('필수 필드를 모두 입력해주세요!');
        return false;
      }

      const formData = new FormData();

      if(title){
        formData.append('title', title);
      }
      if(content){
        formData.append('content', content);
      }
      if(thumbnailUrl){
        formData.append('thumbnail', thumbnailUrl);
      }
      if(author){
        formData.append('author', author);
      }else{
        formData.append('author', "메쎄유람");
      }
        
      if(city){
        formData.append('code', city);
      }

      if(createdAt){
        //console.log(createdAt)
        formData.append('createdAt', createdAt);
      }

      //console.log(thumbnailUrl)

      //생성
      fetch(`https://yuram2.luvmom2020.com/api/payback-events/`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${jwtValue}`
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            console.log(data)
            setSetting(false)
            window.location.reload();
          }
        })
        .catch(error => console.error('에러 발생:', error));
    }

    const mappedPayBackList = payList.length > 0 && payList.map(item => {
      return (
        <li key={item.id}>
          <Link to={"/boardDetail/" + item.id}>
            <div className='img-wrapper'>
              <img src={item.thumbnailUrl || noimg}/>
            </div>
            <div className='mappedPayTitle'>
              {item.title}
            </div>
          </Link>
        </li>
      )
    });
    // const mappedPayBackList = payBackList.map(item => {
    //   return (
    //     <li key={item.id}>
    //       <Link to={item.to}>
    //         <div>
    //           <img src={item.img}/>
    //         </div>
    //         <div className='mappedPayTitle'>
    //           {item.title}
    //         </div>
    //       </Link>
    //     </li>
    //   )
    // });
   
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
           <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                {isUserLoggedIn?(
                  <div className='write_button'>
                    <button type='button' onClick={onSetting}>
                      글쓰기
                    </button>
                  </div>
                ):(
                  null
                )}
                <div className='payBack_wrapper'>
                    <ul>
                      {mappedPayBackList}
                    </ul>
                </div>
                { setting  ? (
                  <div className='write_modal_box'>
                    <label className='write_title'>글쓰기</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>제목</td>
                          <td><input type='text' onChange={(e)=> setTitle(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>작성자</td>
                          <td><input type='text' defaultValue={"메쎄유람"} onChange={(e)=> setAuthor(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>썸네일</td>
                          <td><input type='file' onChange={(e)=> setThumbnailUrl(e.target.files[0])}></input></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>내용</td>
                          <td>
                                 <Editor onEditorChange={handleEditorChange} initState={folder} />
                          </td>
                        </tr>
                        
                       
                      </tbody>
                    </table>
                    <button class="close_btn" onClick={(e)=>{setSetting(false)}}>닫기</button>

                    <div className='button_wrap'>
                      <button type='button' onClick={handleSubmit}>
                          저장
                      </button>
                    </div>
                    
                  </div>
                ):(
                 null
                )}
           </div>
          
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default PayBack;