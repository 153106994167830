//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../static/css/common.css";
import * as XLSX from 'xlsx';

import { scrollToTop } from '../components/Common/TotheTop.js';


function ShowMemberCount() {
    const navigator = useNavigate();
    const api_url = 'https://yuram3.luvmom2020.com/api/v1';
    const [data, setData] = useState([]);

    const [todayTotal, setTodayTotal] = useState(0);
    const [allTotal, setAllTotal] = useState(0);
    const isUserLoggedIn = localStorage.getItem('loggedIn');

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getDate().toString().padStart(2, '0')}`;
    const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}${currentDate.getMinutes().toString().padStart(2, '0')}${currentDate.getSeconds().toString().padStart(2, '0')}`;
    const dateTimeString = `_${formattedDate}_${formattedTime}`;

    
    useEffect(() => {
        scrollToTop();
        if (isUserLoggedIn != null) {
            fetch(`${api_url}/accounts/member_filter/`) // API 엔드포인트를 적절하게 변경하세요.
            .then((response) => response.json())
            .then((result) => {
            
                if(result){
                    console.log(result)
                    setData(result);
                    const todayTotal = result.today_seoul + result.today_hwaseong + result.today_gwangju + result.today_goyang + result.today_seongnam + result.today_suwon;
                    setTodayTotal(todayTotal)
                    const allTotal = result.total_seoul + result.total_hwaseong + result.total_gwangju + result.total_goyang + result.total_seongnam + result.total_suwon;
                    setAllTotal(allTotal)
                }
                
            })
            .catch((error) => {
                console.error('데이터 가져오기 실패:', error);
            });
        } else {
            // 사용자가 로그인하지 않은 경우
            navigator("/yuramAdmin")
        }
    },[]);

    function allExcelDown(){

            const downloadLink = document.createElement('a');
            downloadLink.href = `https://yuram2.luvmom2020.com/api/users/export`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
    }

    function stateExcelDown(state){
        const downloadLink = document.createElement('a');
        downloadLink.href = `https://yuram2.luvmom2020.com/api/users/by-city-export?city=${state}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    const koreanLabels = {
        total_seoul: '서울',
        total_hwaseong: '화성',
        total_goyang: '고양',
        total_seongnam: '성남',
        total_gwangju: '광주',
        total_suwon: '수원',
        total_anyang: '안양',
        total_pyeongtaek: '평택',
        total_daegu: '대구',
        total_suncheon: '순천',
    };

    const renderCityTable = () => {
        const cityKeys = Object.keys(data).filter(key => key.startsWith('total_')&& !key.endsWith('count'));
        
        return (
            <>
            <table>
                <thead>
                    <tr>
                        <th>도시</th>
                        <th>오늘</th>
                        <th>누적</th>
                        <th>다운</th>
                    </tr>
                </thead>
                <tbody>
                    {cityKeys.map((key, index) => {
                        const city = koreanLabels[key] ||  key.replace('total_', '').replace('메세유람_사전등록_', '');
                        const today = data[`today_${key.replace('total_', '')}`];
                        const total =  data[key];
                        return (
                            <tr key={index}>
                                <td>{city}</td>
                                <td>{today}</td>
                                <td>{total}</td>
                                <td><button onClick={()=>{stateExcelDown(`${city}`)}}>다운</button></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <table></table>
            </>
        );
    };
  
        // Count 정보를 표시하는 함수
        const renderCountTable = () => {
            const countKeys = Object.keys(data).filter(key => key.endsWith('count'));
            
            return (
                <table style={{marginTop:"100px"}}>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>누적</th>
                            <th>오늘</th>
                            <th>다운</th>
                        </tr>
                        <tr className='total_tr'>
                            <td>총 합계</td>
                        {countKeys.map((key, index) => (
                                <>
                                <td>{data[key]}</td>
                                </>
                        ))}
                            <td><button onClick={()=>{allExcelDown()}}>다운</button></td>
                        </tr>
                    </tbody>
                </table>
            );
        };

    return (
      <>
      <main className='selectPlaceWrap'v style={{paddingBottom : "200px"}}>
            <div className='admin_table_wrap'>
            <h2>사전등록 총 {data.total_count}명</h2>
                    {/* 이곳에 데이터 표시 */}
                    {renderCityTable()}
                    {renderCountTable()}
            </div>
      </main>
       
      </>
    );
}

export default ShowMemberCount;