//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../static/css/common.css";
import { scrollToTop } from '../components/Common/TotheTop.js';
import {setCookie, getCookie} from '../util/cookies.js';

function YuramAdmin() {
  const api_url = process.env.REACT_APP_API_URL;
  const jwtValue = getCookie('loginToken');
  const navigator = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  
  useEffect(() => {
    scrollToTop();
  },[]);


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  }

  const handleFakeLogin = () =>{
    localStorage.setItem('loggedIn' , true);
    //alert("로그인되었습니다")
    navigator("/ShowMemberCount")
  }

  const handleLogin = () => {
    const credentials = {
      "email": id,
      "password": password
    };
  
  fetch(`https://yuram2.luvmom2020.com/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
  .then(response => response.json())
  .then((response) => {
    //console.log(response)
    if (response.message == '로그인 성공' || response.statusCode == 201 || response.statusCode == 200) {
      localStorage.setItem('loggedIn' , true);
      const time = 3600; //1시간
      const expiration = new Date(Date.now() + time * 1000);
      setCookie('loginToken', response.accessToken , {
        path:"/",
        expires : expiration
      })
      navigator("/ShowMemberCount")

    } else {
      alert('비밀번호가 올바르지 않습니다.');
    }
  })
    .catch((error) => {
      alert('서버와 통신 중 오류가 발생했습니다.');
    });
  };

    

    return (
      <>
        <main className='selectPlaceWrap' style={{"justifyContent" : "center"}}>
          <div className='admin_login_button_wrap'>
            <div className='gogo_home'><Link to="/">처음으로</Link></div>
            <div className='admin_login' onClick={() => setModalVisible(true)}>관리자 로그인</div>
          </div>
          {modalVisible && (
            <div className='admin_login_modal'>
              <h2>아이디입력</h2>
              <input
                type='text'
                placeholder='아이디'
                value={id}
                onChange={(e) => setId(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <h2>비밀번호입력</h2>
              <input
                type='password'
                placeholder='비밀번호'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            <div className='login_button_wrap_modal'>
              <button onClick={handleLogin}>로그인</button>
              <button onClick={() => setModalVisible(false)}>닫기</button>
              {/* <button onClick={handleFakeLogin}>임시로그인</button> */}
            </div>
            </div>
          )}
        </main>
      </>
    );
}

export default YuramAdmin;