//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactQuill from "react-quill";

import "../static/css/common.css";
import "../static/css/Introduce.css";
import introduceImg from "../static/img/manual/introduce.png";
import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';
import introduceTitle from '../static/img/Introduce/introduce_title.png';
import {getCookie} from '../util/cookies.js';

const IntroduceComponent = ({name, date, time, location, titletxt, Content, titleUrl, backgroundUrl}) => {
  const navigator = useNavigate();
  const jwtValue = getCookie('loginToken');
    const isUserLoggedIn = localStorage.getItem('loggedIn');
      const [setting, setSetting] = useState(false);
      const [backgroundImage, setBackgroundImage] = useState();
      const [titleImage, setTitleImage] = useState();
      const [eventName, setEventName] = useState('');
      const [eventDate, setEventDate] = useState('');
      const [eventTime, setEventTime] = useState('');
      const [eventLocation, setEventLocation] = useState('');
      const [subtitle, setSubtitle] = useState('');
      const [subtitleContent, setSubtitleContent] = useState('');
      const city = localStorage.getItem('selectedCity');

      function onSetting(){
        setSetting(true)
        if (!setting) {
          setSetting(true)
        }else{
          setSetting(false)
        }
      }
      function handleSubmit(){
       
        if (!eventName) {
          alert("행사명을 적어주세요")
          return false;
        }
        if (!eventDate) {
          alert("일시를 적어주세요")
          return false;
        }
        if (!eventTime) {
          alert("시간을 적어주세요")
          return false;
        }
        if (!eventLocation) {
          alert("장소를 적어주세요")
          return false;
        }
        // if (!subtitle) {
        //   alert("제목이 무엇인지 적어주세요")
        //   return false;
        // }
        // if (!subtitleContent) {
        //   alert("내용을 설명을 적어주세요")
        //   return false;
        // }

        const formData = new FormData();

        if (city) {
          formData.append('code', city);
        }
        
        if (backgroundImage) {
          formData.append('backgroundImage', backgroundImage);
        }
        
        if (titleImage) {
        formData.append('titleImage', titleImage);
      }
        
        if (eventName) {
        formData.append('eventName', eventName);
      }
        
        if (eventDate) {
        formData.append('eventDate', eventDate);
      }
        
        if (eventTime) {
        formData.append('eventTime', eventTime);
      }
        
        if (eventLocation) {
        formData.append('location', eventLocation);
      }
        
        if (subtitle) {
        formData.append('subtitle', subtitle);
      }
        
        if (subtitleContent) {
        formData.append('subtitleContent', subtitleContent);
      }
        // // 이어지는 데이터 추가

        fetch('https://yuram2.luvmom2020.com/api/introduce', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${jwtValue}`
          },
        })
          .then(response => response.json())
          .then(data => {
            if(data){
             
              if(data.statusCode == 401){
                alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
                navigator("/yuramAdmin")
              }else{
                setSetting(false)
                window.location.reload();
              }
            }
          })
          .catch(error => console.error('에러 발생:', error));
      }
  return (
    <>
      <div className='introduce_main_wrap'>
      {isUserLoggedIn?(
      <div className='button_wrap3'>
        <button type='button' onClick={ setting ? ()=>handleSubmit() : () => onSetting()}>
            { setting  ?(
              <>
                저장
              </>
              ):(
                <>
                수정
                </>
              )}
        </button>
      </div>
    ):(
      null
    )}
        <div className='introduce_title_img_wrap'>
            <img src={titleUrl || introduceTitle} />
        </div>
        <div className='introduce_table_wrap'>
              <table>
                <tbody>
                
                  <tr>
                    <td>행사명</td>
                    <td>
                    { setting  ?(
                      <>

                        <input
                          type='text'
                          placeholder={name}
                          value={eventName} // state와 연결된 value 속성
                          onChange={(e)=>setEventName(e.target.value)}
                        />
                        <span className='required'>*</span>
                        
                      </>
                    ):(
                      <>
                      {name || "준비중입니다"}
                      </>
                    )}

                    </td>
                  </tr>
                  <tr>
                    <td>일시</td>
                    <td>
                    { setting  ?(
                      <>
                        <input 
                          placeholder={date} 
                          type='text'
                          onChange={(e)=>setEventDate(e.target.value)} 
                        />
                        <span className='required'>*</span>
                        <br/>
                      </>
                    ):(
                      <>
                      {date || "준비중입니다"}
                      </>
                    )}
                    </td>
                  </tr>
                  <tr>
                    <td>시간</td>
                    <td>
                    { setting  ?(
                      <>
                        <input placeholder={time} type='text' onChange={(e)=>setEventTime(e.target.value)}/><span className='required'>*</span><br/>
                      </>
                    ):(
                      <>
                      {time || "10:00 ~ 17:00"}
                      </>
                    )}
                    </td>
                  </tr>
                  <tr>
                    <td>장소</td>
                    <td>
                    { setting  ?(
                      <>
                        <input placeholder={location} type='text' onChange={(e)=>setEventLocation(e.target.value)}/><span className='required'>*</span><br/>
                      </>
                    ):(
                      <>
                      {location || "준비중입니다"}
                      </>
                    )}</td>
                  </tr>
                  { setting  ?(
                <tr>
                    <td>백그라운드이미지<br/></td>
                    <td>
                          <input
                            type='file'
                            placeholder={name}
                            onChange={(e)=>setBackgroundImage(e.target.files[0])}
                          />
                    </td>
                </tr>
                    ):(
                      null
                    )}
                  { setting  ? (
                    <tr>
                      <td>타이틀 이미지<br/></td>
                      <td>
                    

                            <input
                              type='file'
                              placeholder={name}
                              onChange={(e)=>setTitleImage(e.target.files[0])}
                            />
                          
                      

                      </td>
                    </tr>
                  ):(
                    null
                  
                    )}
                </tbody>
              </table>
        </div>
        <div className='introduce_detail_wrap'>
          <div className='introduce_title'>
                    { setting  ?(
                              <>
                                <input placeholder={titletxt} type='text' onChange={(e)=>setSubtitle(e.target.value)}/><span className='required'>*</span><br/> 
                              </>
                    ):(
                      <>
                      {titletxt || "세일페스타가 무엇인가요?"}
                      </>
                    )}
          </div>
          <div className='introduce_desc'>
                    { setting  ?(
                              <>
                              <span className='required'>*</span>
                              <ReactQuill 
                                onChange={(content, delta, source, editor) => setSubtitleContent(editor.getHTML())}
                              />
                                <button className="big_btn" type='button' onClick={ ()=>handleSubmit()}>
                                                      <>
                                                        저장
                                                      </>
                                </button>
                              </>
                          ):(
                            <>
                              {Content ?
                              <>
                              <div dangerouslySetInnerHTML={{ __html: Content }} />
                              </>:
                                <>
                                  [유람 베이비페어 : 세일페스타]는 <br />
                                  박람회를 관람하시는 고객님 대상으로 진행되는 할인전입니다. <br />
                                  브랜드 및 제품에 따라서 최저가 구매도 가능하오니, 관심을 가지고 기회를 놓치지 마세요! <br />
                                  메쎄유람과 참가브랜드의 상생으로 진행됩니다.
                                </>
                              }
                            </>
                    )}
          </div>
        </div>

        { setting  ?(
                  <div className='manual_wrap'>
                  <div className='manual_title'>메뉴얼</div>
                    <img style={{width:"100%"}} src={introduceImg}></img>
                  </div>
                ):(
                  null
          )}
      </div>
    </>
  );
};




function Introduce() {
  const isUserLoggedIn = localStorage.getItem('loggedIn');
  const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
  const [codes, setCodes] = useState([]);
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [location, setLocation] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [subtitleContent, setSubtitleContent] = useState("");
  const [titleUrl, setTitleUrl] = useState("");
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [eventName, setEventName] = useState("");
  
    const navi_name = "소개";
    const navi_name_detail = "소개";

  let foundEvent;

    useEffect(() => {
      scrollToTop();

      fetch('https://yuram2.luvmom2020.com/api/introduce', {
        method: 'GET'
      })
        .then(response => response.json())
        .then(data => {
         if(data){
          const codesArray = data.map(item => item.code);
          setCodes(codesArray);
          foundEvent = data.find(item => item.code === city);
          if(foundEvent){
            setEventName(foundEvent.eventName) 
            setEventDate(foundEvent.eventDate) 
            setEventTime(foundEvent.eventTime) 
            setLocation(foundEvent.location) 
            setSubtitle(foundEvent.subtitle) 
            setSubtitleContent(foundEvent.subtitleContent) 
            setBackgroundUrl(foundEvent.backgroundImageUrl)
            setTitleUrl(foundEvent.titleImageUrl)
        }}
        })
        .catch(error => console.error('에러 발생:', error));
    },[]);

    function renderImage(city) {
      // if (city === '메세유람_사전등록_서울') {
      //   return (
      //     <IntroduceComponent
      //       name = {""}
      //       date = {""}
      //       time = {""}
      //       location = {""}
      //     />
      //    );
      // } else if (city === '메세유람_사전등록_화성') {
      //   return (
      //    <IntroduceComponent
      //     name = {"유람 베이비페어"}
      //     date = {"2023.02.01(목) ~ 02.04(일)"}
      //     time = {"10:00 ~ 18:00"}
      //     location = {"장안대학교 컨벤션홀 자아실현관"}
      //    />
      //   );
      // } else if (city === '메세유람_사전등록_광주') {
      //   return (
      //     <IntroduceComponent
      //     name = {"유람 베이비페어"}
      //      date = {"2023.12.07(목) ~ 12.10(일)"}
      //      time = {"10:00 ~ 17:00"}
      //      location = {"염주종합체육관 빛고을체육관"}
      //     />
      //    );
       
      // } else if (city === '메세유람_사전등록_고양') {
      //   return (
      //     <IntroduceComponent
      //       name = {"유람 베이비페어"}
      //      date = {"2023.12.21(목) ~ 12.24(일)"}
      //      time = {"10:00 ~ 18:00"}
      //      location = {"고양 꽃 전시관 실내 2전시장"}
      //     />
      //    );
      // } else if (city === '메세유람_사전등록_성남') {
      //   return (
      //     <IntroduceComponent
      //       name = {"유람 베이비페어"}
      //       date = {"2023.12.28(목) ~ 12.31(일)"}
      //       time = {"10:00 ~ 18:00"}
      //       location = {"동서울대학교 체육관"}
      //     />
      //    );
      // }else if (city === '메세유람_사전등록_평택') {
      //   return (
      //     <IntroduceComponent
      //       name = {"유람 베이비페어"}
      //       date = {"2024.01.18(목) ~ 01.21(일)"}
      //       time = {"10:00 ~ 18:00"}
      //       location = {"국제대학교 창조관"}
      //     />
      //    );
      // }else if (city === '메세유람_사전등록_안양') {
      //   return (
      //     <IntroduceComponent
      //       name = {"유람 베이비페어"}
      //       date = {"2024.02.01(목) ~ 02.04(일)"}
      //       time = {"10:00 ~ 18:00"}
      //       location = {"성결대학교 체육관"}
      //     />
      //    );
      // }
      // else if (city === '메세유람_사전등록_순천') {
      //   return (
      //     <IntroduceComponent
      //       name = {"유람 베이비페어"}
      //       date = {"2024.01.04(목) ~ 01.07(일)"}
      //       time = {"10:00 ~ 18:00"}
      //       location = {"순천대학교 국제문화컨벤션관 범민홀"}
      //     />
      //    );
      // } 
      // else if (city === '메세유람_사전등록_대구') {
      //   return (
      //     <IntroduceComponent
      //       name = {"유람 베이비페어"}
      //       date = {"2024.02.15(목) ~ 01.18(일)"}
      //       time = {"10:00 ~ 18:00"}
      //       location = {"계명문화대학교 체육관"}
      //     />
      //    );
      // 
    //  if(city === '메세유람_사전등록_고양'){
      
    // }else {
    //     return <div>준비중 입니다.</div>;
    //   }
    if(city){
      return (<IntroduceComponent
        name={eventName}
        date={eventDate}
        time={eventTime}
        location={location}
        titletxt={subtitle}
        Content={subtitleContent}
        titleUrl={titleUrl}
        backgroundUrl={backgroundUrl}
      />
      )
    }
    }

    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
           <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                <div className='introduce_wrap'>
                          {renderImage(city)}
                </div>
           </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default Introduce;