//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import ReactQuill from "react-quill";
import Editor from "./editor.js";

import "../static/css/common.css";
import "../static/css/payBack.css";

import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import box_1 from "../static/img/yuram.png";
import { scrollToTop } from '../components/Common/TotheTop.js';
import {getCookie} from '../util/cookies.js';

function BrandEvent() {
  const navigator = useNavigate();
  const isUserLoggedIn = localStorage.getItem('loggedIn');
    const jwtValue = getCookie('loginToken');
    const [setting, setSetting] = useState(false);
    const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    const navi_name = "베세페 이벤트";
    const navi_name_detail = "참가브랜드 이벤트";

    //초기값
    const [title, setTitle] = useState("");
    const [content, setcontent] = useState("");
    const [thumbnailUrl, setEmailthumbnailUrl] = useState("");
    const [author, setAuthor] = useState("메세유람");
    const [contact, setContact] = useState("010-9241-7188");
    const [email, setEmail] = useState("m_yuram@naver.com");
    const [createdAt, setCreatedAt] = useState();

    const [showauthor, setShowAuthor] = useState("메쎄유람");
    const [showcontact, setShowContact] = useState("010-9241-7188");
    const [showemail, setShowEmail] = useState("m_yuram@naver.com");
    const [itemData, setItemData] = useState([]);


    function onSetting(){
      setSetting(true)
      if (!setting) {
        setSetting(true)
      }else{
        setSetting(false)
      }
    }
    const handleEditorChange = (html) => {
      // 여기서 전달된 값(html)을 사용할 수 있습니다.
      console.log(html);
      setcontent(html);
    };
    function handleSubmit(){
      const formData = new FormData();
      if(!title || !content){
        alert("모든 내용을 입력해주세요")
        return false;
      }
      if(title){
        formData.append('title', title);
      }
      if(content){
        formData.append('content', content);
      }
      if(thumbnailUrl){
        formData.append('thumbnailUrl', thumbnailUrl);
      }
      if(author){
        formData.append('author', author);
      }else{
        formData.append('author', "메쎄유람");
      }
      if(city){
        formData.append('code', city);
      }
      if(createdAt){
        formData.append('createdAt', createdAt);
      }
      //생성
      fetch(`https://yuram2.luvmom2020.com/api/brand-events/`, {
        headers: {
          'Authorization': `Bearer ${jwtValue}`
        },
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data =>{
          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            console.log(data)
            setSetting(false)
            window.location.reload();
          }
        })
        .catch(error => console.error('에러 발생:', error));
      
      
    }

    useEffect(() => {
      scrollToTop();
        //생성일 보내기
        function toLocalISOString(date) {
          const off = date.getTimezoneOffset();
          const absoff = Math.abs(off);
          return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
        }
        const date = new Date();
        const formatedate = toLocalISOString(date);
        setCreatedAt(formatedate)


      fetch(`https://yuram2.luvmom2020.com/api/brand-events/${city}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${jwtValue}`
        },
      })
        .then(response => response.json())
        .then(data => {
         
            setItemData(data)
        })
        .catch(error => console.error('에러 발생:', error));
    },[]);
    
    let payBackList = [];
    if(city){
      payBackList = [
        {
          title: "[루미] 박람회 특가 이벤트",
          img: box_1,
          to : "/BrandEventDetail/0"
      },
      ]
    }else{
      payBackList = []

    }
    

    const mappedPayBackList = itemData.length > 0 && itemData.map(item => {
      return (
        <li key={item.id}>
          <Link to={"/BrandEventDetail/" + item.id}>
          <div className='event_img_wrap'>
            <img src={item.thumbnailUrl || box_1}/>
          </div>
          <div className='mappedPayTitle'>
            {item.title}
          </div>
          </Link>
        </li>
      )
    });
   
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
            <div className='detail_cont'>
                <div className='detail_tit'>{navi_name_detail}</div>
                {isUserLoggedIn?(
                  <div className='write_button'>
                    <button type='button' onClick={onSetting}>
                      글쓰기
                    </button>
                  </div>
                ):(
                  null
                )}
                <div className='payBack_wrapper'>
                    <ul>
                      {mappedPayBackList}
                    </ul>
                </div>
                
                { setting  ? (
                  <div className='write_modal_box'>
                    <label className='write_title'>글쓰기</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>제목</td>
                          <td><input type='text' onChange={(e)=> setTitle(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>작성자</td>
                          <td><input type='text' defaultValue={"메쎄유람"} onChange={(e)=> setAuthor(e.target.value)}></input></td>
                        </tr>
                        {/* <tr>
                          <td>연락처</td>
                          <td><input type='text' defaultValue={"010-9241-7188"} onChange={(e)=> setContact(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>이메일</td>
                          <td><input type='text' defaultValue={"m_yuram@naver.com"} onChange={(e)=> setEmail(e.target.value)}></input></td>
                        </tr> */}
                        <tr>
                          <td>썸네일</td>
                          <td><input type='file' onChange={(e)=> setEmailthumbnailUrl(e.target.files[0])}></input></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>내용</td>
                          <td>
                                {/* <ReactQuill 
                                  onChange={(content, delta, source, editor) => setcontent(editor.getHTML())}
                                /> */}
                                 <Editor onEditorChange={handleEditorChange}/>
                            
                          </td>
                        </tr>
                        
                       
                      </tbody>
                    </table>
                    <button className="close_btn" onClick={(e)=>{setSetting(false)}}>닫기</button>
                    <div className='button_wrap'>
                    <button type='button' onClick={handleSubmit}>
                        저장
                    </button>
                    </div>
                  </div>
                ):(
                 null
                )}
            </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default BrandEvent;