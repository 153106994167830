//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactQuill from "react-quill";
import Editor from "./editor.js";

import {getCookie} from '../util/cookies.js';
import "../static/css/common.css";
import "../static/css/Introduce.css";

import MiniNavi from "../components/Common/MiniNavi"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';

//img
import b1 from "../static/img/main/boardDetail/b1.png";
import b2 from "../static/img/main/boardDetail/b2.png";
import b3 from "../static/img/main/boardDetail/b3.png";
import b4 from "../static/img/main/boardDetail/b4.png";
import b5 from "../static/img/main/boardDetail/b5.png";
import b6 from "../static/img/main/boardDetail/b6.png";
import b7 from "../static/img/main/boardDetail/b7.png";
//서울
import sb1 from "../static/img/main/boardDetail/sb1.png";
import sb2 from "../static/img/main/boardDetail/sb2.png";
import sb3 from "../static/img/main/boardDetail/sb3.png";
import sb4 from "../static/img/main/boardDetail/sb4.png";
import sb5 from "../static/img/main/boardDetail/sb5.png";
import sb6 from "../static/img/main/boardDetail/sb6.png";
//화성
import hb1 from "../static/img/main/boardDetail/hb1.jpg";
import hb2 from "../static/img/main/boardDetail/hb2.png";
import hb3 from "../static/img/main/boardDetail/hb3.png";
//광주
import g1 from "../static/img/main/event/GwangJu/g1.png"
import g2 from "../static/img/main/event/GwangJu/g2.png"
import g3 from "../static/img/main/event/GwangJu/g3.png"
import g4 from "../static/img/main/event/GwangJu/g4.png"
import g5 from "../static/img/main/event/GwangJu/g5.png"
import g6 from "../static/img/main/event/GwangJu/g6.png"

//공통
import eventB1 from "../static/img/main/boardDetail/evB1.png"
import eventB2 from "../static/img/main/boardDetail/evB2.png"




function BoardDetail() {
  const jwtValue = getCookie('loginToken');

  const navigator = useNavigate();
  const isUserLoggedIn = localStorage.getItem('loggedIn');
  const [setting, setSetting] = useState(false);
  const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
  const [itemData, setItemData] = useState([]);
  const [updateAt, setUpdateAt] = useState();
  
  const [showtitle, setShowtitle] = useState("");
  const [showthumbnailUrl, setshowthumbnailUrl] = useState("");
  const [showauthor, setShowAuthor] = useState("메쎄유람");
  const [showcontact, setShowContact] = useState("010-9241-7188");
  const [showemail, setShowEmail] = useState("m_yuram@naver.com");
  const [showcontent, setshowcontent] = useState("m_yuram@naver.com");

  const [title, setTitle] = useState();
  const [content, setcontent] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [author, setAuthor] = useState();
  const [contact, setContact] = useState();
  const [email, setEmail] = useState();
  const [createdAt, setCreatedAt] = useState();

  const { id } = useParams();
  const navi_name = "베세페 이벤트";
  const navi_name_detail = "유람 이벤트/페이백안내";

  useEffect(() => {
    scrollToTop();
    //수정일 보내기
    function toLocalISOString(date) {
      const off = date.getTimezoneOffset();
      const absoff = Math.abs(off);
      return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
    }
    const date = new Date();
    const formatedate = toLocalISOString(date);
    setUpdateAt(formatedate)
    //const selectedItem = parseInt(id, 10);
    fetch(`https://yuram2.luvmom2020.com/api/payback-events/${city}/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwtValue}`
      }
    })
    .then(response => response.json())
    .then(data => {
      //setIsset(data.code? true : false);
      //console.log(data)
      setItemData(data)
      setShowtitle(data.title)
      setshowthumbnailUrl(data.thumbnailUrl)
      setShowAuthor(data.author)
      setShowContact(data.contact)
      setShowEmail(data.email)
      setshowcontent(data.content)
      
    })
    .catch(error => console.error('에러 발생:', error));

  },[]);

  
  function onSetting(){
    setSetting(true)
    if (!setting) {
      setSetting(true)
    }else{
      setSetting(false)
    }
  }
  
  function handleSubmit(){
    const formData = new FormData();
    if(!content){
      alert("내용을 입력해주세요")
      return false;
    }
    if(title || showtitle){
      formData.append('title', title || showtitle);
    }
    if(content){
      formData.append('content', content);
    }
    if(thumbnailUrl){
      formData.append('thumbnail', thumbnailUrl);
    }
    if(author || showauthor){
      formData.append('author', author || showauthor);
    }
    if(city){
      formData.append('code', city);
    }
   
     //수정
     fetch(`https://yuram2.luvmom2020.com/api/payback-events/${city}/${id}`, {
      method: 'PUT',
      body: formData,
      headers: {
        'Authorization': `Bearer ${jwtValue}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if(data.statusCode == 401){
          alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
          navigator("/yuramAdmin")
        }else{
          //console.log(data)
          setSetting(false);
          window.location.reload();
        }
      })
      .catch(error => console.error('에러 발생:', error));
  }
  function handleDelete(){
    //삭제
    const result = window.confirm('삭제하시겠습니까?');
   if (result) {
     fetch(`https://yuram2.luvmom2020.com/api/payback-events/${city}/${id}`, {
     method: 'Delete',
     headers: {
      'Authorization': `Bearer ${jwtValue}`
      }
   })
     .then(response => response.json())
     .then(data => {
      if(data.statusCode == 401){
        alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
        navigator("/yuramAdmin")
      }else{
        alert("삭제되었습니다")
        navigator('/payBack');
        window.location.reload();
      }
     })
     .catch(error => console.error('에러 발생:', error));
   }
 }
  let PayBackData = [];
  if(city == "메세유람_사전등록_수원"){
    PayBackData = [
        {
          title:"상품권 현장판매",
          wrDate:"2023-10-04 17:29:44",
          writer:"메쎄유람",
          phone:"	010-9241-7188",
          email:"m_yuram@naver.xom",
          src:b1,
        },
        {
          title:"상품권 75%할인 사전판매",
          wrDate:"2023-09-26 10:45:57",
          writer:"메쎄유람",
          phone:"010-9241-7188",
          email:"m_yuram@naver.xom",
          src:b2,
        }
        ,
        {
          title:"	상품권 75% 할인특가",
          wrDate:"2023-09-15 14:57:58",
          writer:"메쎄유람",
          phone:"	010-9241-7188",
          email:"	m_yuram@naver.xom",
          src:b3,
        }
        ,
        {
          title:"구매 페이백 이벤트",
          wrDate:"2023-09-15 14:00:48",
          writer:"메쎄유람",
          phone:"010-9241-7188",
          email:"	m_yuram@naver.xom",
          src:b4,
        }
        ,
        {
          title:"친구추가 이벤트",
          wrDate:"2023-09-15 13:52:43",
          writer:"메쎄유람",
          phone:"010-9241-7188",
          email:"m_yuram@naver.xom",
          src:b5,
        }
        ,
        {
          title:"매일 선착순 선물증정",
          wrDate:"2023-09-15 13:32:32",
          writer:"메쎄유람",
          phone:"	010-9241-7188",
          email:"	m_yuram@naver.xom",
          src:b6,
        },
        {
          title:"임신축하선물",
          wrDate:"2023-09-12 13:14:14",
          writer:"메쎄유람",
          phone:"010-9241-7188",
          email:"m_yuram@naver.xom",
          src:b7,
        },
        {
          title:"스탬프투어!",
          wrDate:"2023-11-13 14:01:34",
          writer:"메쎄유람",
          phone:"	010-9241-7188",
          email:"m_yuram@naver.xom",
          src:eventB1,
        },
        {
          title:"임신축하선물증정",
          wrDate:"2023-11-13 14:01:34",
          writer:"메쎄유람",
          phone:"	010-9241-7188",
          email:"m_yuram@naver.xom",
          src:eventB2,
          Comment: "*하루 30명 선착순 입니다."
        }

    ];
  }else if(city == "메세유람_사전등록_서울"){
    PayBackData = [
      {
        title:"최저가 구매 방법 안내",
        wrDate:"2023-10-17 12:49:44",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:sb1,
      },
      {
        title:"선착순 상품권 무료증정",
        wrDate:"2023-10-17 12:49:44",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:sb2,
      },
      {
        title:"선착순 애착인형 무료증정",
        wrDate:"2023-10-17 12:49:44",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:sb3,
      },
      {
        title:"친구추가 이벤트",
        wrDate:"2023-10-17 12:49:44",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:sb4,
      },
      {
        title:"임신축하 선물팩",
        wrDate:"2023-10-17 12:49:44",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:sb5,
      },
      {
        title:"브랜드페이백",
        wrDate:"2023-10-17 12:49:44",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:sb6,
      },
      {
        title:"스탬프투어!",
        wrDate:"2023-11-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:eventB1,
      },
      {
        title:"임신축하선물증정",
        wrDate:"2023-11-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:eventB2,
        Comment: "*하루 30명 선착순 입니다."
      }

    ];
  }else if(city == "메세유람_사전등록_화성"){
    PayBackData = [
      {
        title:"구매 캐시백 쏜다!",
        wrDate:"2023-11-01 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:hb1,
      },
      {
        title:"유아용품 쏜다!",
        wrDate:"2023-11-01 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:hb2,
      },
      {
        title:"매일매일 쏜다",
        wrDate:"2023-11-01 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:hb3,
      },
      {
        title:"스탬프투어!",
        wrDate:"2023-11-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:eventB1,
      },
      {
        title:"임신축하선물증정",
        wrDate:"2023-11-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:eventB2,
        Comment: "*하루 30명 선착순 입니다."
      }
    ]
  }else if(city ==  "메세유람_사전등록_광주"){
    PayBackData = [
      {
        title:"구매 캐시백 쏜다!",
        wrDate:"2023-11-21 10:31:25",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g1,
      },
      {
        title:"맘스팡 회원 이벤트!",
        wrDate:"2023-11-21 10:31:25",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g2,
      },
      {
        title:"매일매일 쏜다!",
        wrDate:"2023-11-21 10:31:25",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g3,
      },
      {
        title:"임신축하선물증정",
        wrDate:"2023-11-21 10:31:25",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g4,
        Comment: "*하루 30명 선착순 입니다."
      },
      {
        title:"유아용품 쏜다!",
        wrDate:"2023-11-21 10:31:25",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g5,
      },
      {
        title:"스탬프투어",
        wrDate:"2023-11-21 10:31:25",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g6,
      },
    ]
  }else{
    PayBackData = [
      {
        title:"스탬프투어!",
        wrDate:"2023-12-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:eventB1,
      },
      {
        title:"구매캐시백쏜다",
        wrDate:"2023-12-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g1,
      },
      {
        title:"유아용품증정",
        wrDate:"2023-12-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g5,
      },
      {
        title:"할인쿠폰증정",
        wrDate:"2023-12-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g3,
      },
      {
        title:"임신축하선물증정",
        wrDate:"2023-12-13 14:01:34",
        writer:"메쎄유람",
        phone:"	010-9241-7188",
        email:"m_yuram@naver.xom",
        src:g4,
      }
    ]
  }
  const selectedItem = PayBackData[parseInt(id, 10)];

  const formattedDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
  
    const date = new Date(dateString);
    return date.toLocaleString('ko-KR', options);
  };
  const handleEditorChange = (html) => {
    // 여기서 전달된 값(html)을 사용할 수 있습니다.
    //console.log(html);
    setcontent(html);
  };
    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
          
        <MiniNavi navi_name={navi_name}/>
        <div className='detail_cont'>
          <div className='detail_tit'>{navi_name_detail}</div>
          <div className='back_btn'><Link to="/payBack">뒤로가기</Link></div>
          {/* 선택된 항목의 정보를 출력합니다. */}
          <div className='detail_table_wrap2'>
          {isUserLoggedIn?(
                <div className='button_wrap'>
                  <button type='button' onClick={ setting ? ()=>handleSubmit() : () => onSetting()}>
                      { setting  ?(
                        <>
                          저장
                        </>
                        ):(
                          <>
                          수정
                          </>
                        )}
                  </button>
                  <button type='button' onClick={handleDelete} style={{marginLeft:"10px", background:"red"}}>삭제</button>
                </div>
              ):(
                null
              )}
            <table>
                <tbody>
                  <tr>
                    <td>제목</td>
                    <td>{itemData && itemData.title}</td>
                  </tr>
                  <tr>
                    <td>작성자</td>
                    <td>{itemData.author}</td>
                  </tr>
                </tbody>
                
            </table>
          </div>
          
          <div className='contents_div'>
            {/* <img src={selectedItem.src} style={{"width" : "100%", "display" : "block"}}/> */}
            {/* <img src={itemData.thumbnailUrl}/> */}
                {itemData.content ?
                  <>
                  <div dangerouslySetInnerHTML={{ __html: itemData.content }} />
                  </>
                  : 
                  <>
                  </>
                }
            </div>
            { setting  ?(
                  <div className='write_modal_box'>
                    <label className='write_title'>글쓰기</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>제목</td>
                          <td><input type='text' defaultValue={showtitle} onChange={(e)=> setTitle(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>작성자</td>
                          <td><input type='text' defaultValue={ showauthor || "메쎄유람"} onChange={(e)=> setAuthor(e.target.value)}></input></td>
                        </tr>
                        {/* <tr>
                          <td>연락처</td>
                          <td><input type='text' defaultValue={ showcontact || "010-9241-7188"} onChange={(e)=> setContact(e.target.value)}></input></td>
                        </tr>
                        <tr>
                          <td>이메일</td>
                          <td><input type='text' defaultValue={ showemail || "m_yuram@naver.com"} onChange={(e)=> setEmail(e.target.value)}></input></td>
                        </tr> */}
                        <tr>
                          <td>썸네일</td>
                          <td><input type='file' onChange={(e)=> setThumbnailUrl(e.target.files[0])}></input>
                            <div className='sumnail_img'>
                                현재썸네일<br/>
                                <img src={showthumbnailUrl}></img>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>내용</td>
                          <td>
                                {/* <ReactQuill 
                                  defaultValue={showcontent}
                                  onChange={(content, delta, source, editor) => setcontent(editor.getHTML())}
                                /> */}
                              <Editor onEditorChange={handleEditorChange}/>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                    <button className="close_btn" onClick={(e)=>{setSetting(false)}}>닫기</button>
                    <div className='button_wrap'>
                    <button type='button' onClick={handleSubmit}>
                        저장
                    </button>
                    </div>
                  </div>
                ):(
                 null
                )}   
        </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default BoardDetail;