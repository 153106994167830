//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import "../static/css/common.css";
import "../static/css/Introduce.css";

import MiniNavi from "../components/Common/MiniNavi.js"
import Navi from "../components/Common/Navi.js";
import Footer from "../components/Common/Footer.js";
import { scrollToTop } from '../components/Common/TotheTop.js';
import {getCookie} from '../util/cookies.js';
import excelImg from "../static/img/manual/brandList.PNG";
import imgEx from "../static/img/manual/20231218021004.jpeg";

function BrandListExample() {
  const navigator = useNavigate();
  const jwtValue = getCookie('loginToken');


    const isUserLoggedIn = localStorage.getItem('loggedIn');
    const [setting, setSetting] = useState(false);
    const [city, setCity] = useState(localStorage.getItem('selectedCity') || '');
    const [itemData, setItemData] = useState([]);
    const [excel, setExcel] = useState("");
    //초기값
    const [title, setTitle] = useState("");
    const [content, setcontent] = useState("");
    const [thumbnailUrl, setEmailthumbnailUrl] = useState("");
    const [author, setAuthor] = useState("메쎄유람");
    const [contact, setContact] = useState("010-9241-7188");
    const [email, setEmail] = useState("m_yuram@naver.com");
    const [createdAt, setCreatedAt] = useState();

    function onSetting(){
      setSetting(true)
      if (!setting) {
        setSetting(true)
      }else{
        setSetting(false)
      }
    }


    const handleRowClick = (postId) => {
      // 특정 경로로 이동하는 함수
      navigator(`/brandListDetail/${postId}`); // 예시 경로: '/posts/7' (postId에 해당하는 식별자)
    };



    const navi_name = "관람안내";
    const navi_name_detail = "참가브랜드리스트";
      useEffect(() => {
        scrollToTop();

        //생성일 보내기
        function toLocalISOString(date) {
          const off = date.getTimezoneOffset();
          const absoff = Math.abs(off);
          return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
        }
        const date = new Date();
        const formatedate = toLocalISOString(date);
        setCreatedAt(formatedate)



        fetch(`https://yuram2.luvmom2020.com/api/brandlist/${city}/`, {
        method: 'GET',
      })
        .then(response => response.json())
        .then(data => {
         if(data){
          //console.log(data)
          setItemData(data)
          }
        })
        .catch(error => console.error('에러 발생:', error));
      },[]);

    function handleSubmit(){
      setSetting(false)
    
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      if(!title){
        alert("제목을 입력해주세요")
        return false;
      }
      
      const config = {
        "title": title,
        "content": content,
        "code": city,
        "author": author,
        "contact": contact,
        "email": email,
        "createdAt": createdAt,
      }
      console.log(config)

      //일반게시판생성
      fetch(`https://yuram2.luvmom2020.com/api/brandlist/`, {
        headers: myHeaders,
        method: 'POST',
        body: JSON.stringify(config),
        headers: {
          'Authorization': `Bearer ${jwtValue}`
        }
      })
        .then(response => response.json())
        .then(data =>{
          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            window.location.reload();
          }
        })
        .catch(error => console.error('에러 발생:', error));
      
    }

    function handleFileUpload(){
     
        // 파일 업로드용 입네당
      const uploadformData = new FormData();
      if(!excel){
        alert("엑셀파일을 업로드 해주세요")
        return false
      }
      if(!author){
        alert("작성자를 입력해주세요")
        return false
      }
      if(excel){
        uploadformData.append('file', excel);
        console.log('file', excel);
      }
      if(city){
        uploadformData.append('code', city);
        console.log('code', city);
      }
      if(author){
        uploadformData.append('author', author);
        console.log('author', author);
      }
      if(createdAt){
        uploadformData.append('createdAt', createdAt);
        console.log('createdAt', author);
      }
      //업로드생성
      fetch(`https://yuram2.luvmom2020.com/api/brandlist/${city}/upload`, {
        method: 'POST',
        body: uploadformData,
        headers: {
          'Authorization': `Bearer ${jwtValue}`
        }
      })
        .then(response => response.json())
        .then(data => {
          if(data.statusCode == 401){
            alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
            navigator("/yuramAdmin")
          }else{
            setSetting(false)
          }
        })
        .catch(error => console.error('에러 발생:', error));
      
      
    }

    function renderCategory(category, items) {
        return (
            <div className='render_category'>
                <div className='category_title'>{category}</div>
                <ul className='category_item_ul'>
                    {items.map((item, index) => (
                    <li key={index}>
                        <div className='item_title'>{item.title}</div>
                        <div className='item_cont'>{item.cont}</div>
                    </li>
                    ))}
                </ul>
            </div>
        );
    }

    return (
      <>
      <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>
           <div className='detail_cont' style={{display:"none"}}>
                <div className='detail_tit'>{navi_name_detail}</div>
                {isUserLoggedIn?(
                  <div className='write_button'>
                    <button className="big_btn" type='button' onClick={onSetting}>
                    엑셀파일로업로드
                    </button>
                  </div>
                ):(
                  null
                )}
                <div>
                { setting  ? (
                  <>
                  <div className='write_modal_box'>
                    <button className="close_btn" onClick={(e)=>{setSetting(false)}}>닫기</button>
                    <label className='write_title'>글쓰기</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>작성자</td>
                          <td><input type='text' defaultValue={""} onChange={(e)=> setAuthor(e.target.value)}></input></td>
                        </tr>
                      </tbody>
                    </table>
                   
                    <table>
                      <tbody>
                        <tr>
                          <td>엑셀업로드</td>
                          <td><input type='file' onChange={(e)=> setExcel(e.target.files[0])}></input></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='button_wrap'>
                    <button type='button' onClick={handleFileUpload}>
                        저장
                    </button>
                    </div>

                    <div className='manual_wrap' style={{"margin-top":"30px"}}>
                    <div className='manual_title'>예시1 <div className='manual_txt'>엑셀로 작성하여 업로드하시면 됩니다!</div></div>
                    <table className='example_table' style={{"margin-bottom":"30px"}}>
                      <thead>
                        <tr>
                          <td>제목</td>
                          <td>내용</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>[업체이름]제품이름</td>
                          <td>상세내용1</td>
                        </tr>
                        <tr>
                          <td>[업체이름]제품이름</td>
                          <td>상세내용2</td>
                        </tr>
                        <tr>
                          <td>[업체이름]제품이름</td>
                          <td>상세내용3</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='manual_title'>예시2 <div className='manual_txt'>엑셀로 작성하여 업로드하시면 됩니다!</div></div>
                    <img style={{"width":"800px"}} src={excelImg}></img>
                    </div>
                  </div>

                  </>
                ):(
                 null
                )}
                </div>
              
              {itemData && itemData.length > 0? (
                <div  className='brandList_wrap'>
                <table>
                  <thead>
                    <tr>
                    <th>번호</th>
                    <th>제목</th>
                    {/* <th>내용</th> */}
                    <th>작성자</th>
                    {/* <th>연락처</th>
                    <th>이메일</th> */}
                    <th>조회수</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemData.map((post) => (
                      <tr key={post.id} onClick={() => handleRowClick(post.id)}>
                        <td>{post.id}</td>
                        <td>{post.title}</td>
                        {/* <td>{post.content}</td> */}
                        <td>{post.author}</td>
                        {/* <td>{post.contact}</td>
                        <td>{post.email}</td> */}
                        <td>{post.hit || 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              ):
              (
                <div className='text_center'>작성된 게시글이 없습니다</div>
              )
              }
              
           </div>
           <div className='detail_cont'>
            <img src={imgEx}></img>
           </div>
        </main>
      </div>
     <Footer city={city} setCity={setCity} />
      </>
    );
}

export default BrandListExample;