import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCookie } from '../util/cookies.js';

import "../static/css/common.css";
import "../static/css/selectPlace.css";

import ListLocation from "./ListLocation.js"


import { scrollToTop } from '../components/Common/TotheTop.js';

import title from "../static/img/selectPlace/title_img.png";
import titleLuvmom from "../static/img/selectPlace/luvmomLogo.svg";
import titleLuvmomMobile from "../static/img/selectPlace/luvmomLogoMobile.svg";
import title2 from "../static/img/selectPlace/title_img2.png";
import star from "../static/img/selectPlace/star.png";
import star2 from "../static/img/selectPlace/star2.png";
import locate from "../static/img/selectPlace/location.png";

function SelectPlace() {
  const jwtValue = getCookie('loginToken');
  const [city, setCity] = useState("");
  const [items, setItems] = useState([]);

  function formatDate(dateString) {
    const startDate = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', weekday: 'short' };

    const formattedDate = startDate.toLocaleString('ko-KR', options)
      .replace(/,/, ''); // 쉼표 제거

    return formattedDate;
  }
  const fetchData = async (retryCount = 0) => {
    const maxRetries = 3;
    const timeout = 1000; // 1 second timeout

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      const response = await fetch('https://yuram3.luvmom2020.com/api/v1/accounts/fair/list/', {
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setItems(data);
    } catch (error) {
      console.error('Error fetching fair list:', error);
      if (retryCount < maxRetries) {
        console.warn(`Retrying request... Attempt ${retryCount + 1}`);
        await new Promise(resolve => setTimeout(resolve, timeout));
        fetchData(retryCount + 1);
      }
    }
  };


  useEffect(() => {
    scrollToTop();
    fetchData();
  }, []);
  const handleLinkClick = (element) => {
    setCity(element);
    localStorage.setItem('selectedCity', element); //선택한 상태로 변경
  }
  return (
    <>
      <main className='selectPlaceWrap'>
        <div className='selectPlaceDom1'>
          <div className='title_img_wrap pc'>
            <img src={title} />
          </div>
          <div className='title_img_wrap mobile'>
            <div className='flex background_star'>
              <img src={star} />
              <img src={star2} />
            </div>
            <img src={title2} />
          </div>
          <div className='select_place_cont'>
            <ul>
              {items && items.map((item, index) => (
                <ListLocation
                  key={index}
                  index={index + 1}
                  handleLinkClick={handleLinkClick}
                  code={item.code}
                  name={item.name}
                  startDate={formatDate(item.startDate)}
                  endDate={formatDate(item.endDate)}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className='selectPlaceDom2'>
          <div className='location_wrap'>
            <img src={locate} />
          </div>
        </div>
      </main>
    </>
  );
}

export default SelectPlace;