//성민퍼블 컨텐츠 필요
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../static/css/common.css";
import "../static/css/fairCreate.css";
import { scrollToTop } from '../components/Common/TotheTop.js';
import uuid from 'react-uuid'
import FairUpdateModal from './FairModify.js';
import main_ex from "../static/img/example/main.png";
import {getCookie} from '../util/cookies.js';
import DaumPostcode from 'react-daum-postcode';
import visit from "../static/img/manual/visit.PNG";
import main_popup from "../static/img/manual/main.png";
import edit_tip from "../static/img/manual/edit_tip.png";
import edit_tip2 from "../static/img/manual/edit_tip2.png";

function FairCreate() {
    const navigator = useNavigate();
    const jwtValue = getCookie('loginToken');
    const isUserLoggedIn = localStorage.getItem('loggedIn');
    const [loading, setLoading] = useState(false);

    // 박람회 등록시 필요한 변수
    const [fairName , setFairName] = useState("");
    const [fairCode , setFairCode] = useState();
    const [fairShow , setFairShow] = useState();
    const [fairStart , setFairStart] = useState();
    const [fairEnd , setFairEnd] = useState();
    const [bannerImage , setBannerImage] = useState();
    const [parameter , setParmeter] = useState();
    const [getFairData, setFairData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [fairIdToDelete, setFairIdToDelete] = useState(null);
    const [fairBannerUrl, setFairBannerUrl] = useState(null);

    // 오시는길에 생성/수정할때 쓸 변수 선언
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [addressBuilding, setAddressBuilding] = useState('');
    const [visible, setVisible] = useState(false); 


    // 박람회 수정시 필요한 변수
    const [fairIdUpdate , setFairIdUpdate] = useState(null);
    const [fairNameUpdate , setFairNameUpdate] = useState("");
    const [fairCodeUpdate , setFairCodeUpdate] = useState();
    const [fairShowUpdate , setFairShowUpdate] = useState();
    const [fairStartUpdate , setFairStartUpdate] = useState();
    const [fairEndUpdate , setFairEndUpdate] = useState();
    const [bannerImageUpdate , setBannerImageUpdate] = useState();
    const [parameterUpdate , setParmeterUpdate] = useState();
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [fairBannerUrlUpdate, setFairBannerUrlUpdate] = useState(null);

    const [manual, setManual] = useState(false);

    useEffect(() => {
        scrollToTop();
        setParmeter(uuid());
        // JWT 토큰이 있다면 요청 헤더에 추가
       
            fetch('https://yuram2.luvmom2020.com/api/fairlist/admin/list', {
                headers: {
                    'Authorization': `Bearer ${jwtValue}`
                }
            })
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if(result.statusCode == 401){
                    alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
                    navigator("/yuramAdmin")
                }else{
                    setFairData(result);
                }
            })
            .catch((error) => {
                console.error('데이터 가져오기 실패:', error);
            });
        
    }, []);

    // 이미지 모달 
    const openModal = (imageSrc) => {
        setModalImage(imageSrc);
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };
   
    const getAddressCoords = (address) => {
        return new Promise((resolve, reject) => {
          const geoCoder = new window.kakao.maps.services.Geocoder();
  
          geoCoder.addressSearch(address, (result, status) => {
            if (status === window.kakao.maps.services.Status.OK) {
              const coords = new window.kakao.maps.LatLng(result[0].x, result[0].y);
              resolve(coords);
            } else {
              reject(status);
            }
          });
        });
      };
    // 주소 검색이 끝났을때 동작
    const handleComplete = (data) => {
        const addressValue = data.address;
        const buildingName = data.buildingName;
        getAddressCoords(addressValue)
        .then((coords) => {
          // 위도와 경도 정보를 가져온 후 원하는 작업 수행
          setLatitude(coords.getLat())
          setLongitude(coords.getLng())
          setAddressBuilding(buildingName)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  
        setVisible(false);
        setAddress(addressValue);
      };


    // 박람회 수정 모달
    const openUpdateModal = (fairId, fairName, fairCode, fairStartDate, fairEndDate, fairStartBanner, fairIsVisible, extraParameter , startBannerUrl) => {
        setShowUpdateModal(true);
        setFairIdUpdate(fairId);
        setFairNameUpdate(fairName);
        setFairCodeUpdate(fairCode);
        setFairShowUpdate(fairIsVisible);
        setFairStartUpdate(fairStartDate);
        setFairEndUpdate(fairEndDate);
        setBannerImageUpdate();
        setParmeterUpdate(extraParameter);
        setFairBannerUrlUpdate(startBannerUrl);
    };
    // 주소창 style
    const [addressStyle, setAddressStyle] = useState({
        maxWidth: '500px',
        border: '1px solid #ccc',
        position: 'absolute', // Change this value according to your needs
        borderRadius: '4px',
        padding: '10px',
        backgroundColor: '#fff',
        color: '#000',
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        fontWeight: 'normal',
        zIndex: "30",
      });
    // 박람회 생성하는 함수
    function submitFairCreate(){

            if(bannerImage){
                console.log('startBanner', bannerImage);
            }
            if(fairBannerUrl){
                console.log('startBannerURL', fairBannerUrl);
            }

            if(!fairName){
                alert("박람회이름을 적어주세요");
                return false;
            }
           
            if(!fairShow){
                alert("노출/미노출 여부를 선택해주세요");
                return false;
            }
            if(!fairStart){
                alert("시작일을 적어주세요");
                return false;
            }
            if(!fairEnd){
                alert("종료일을 적어주세요");
                return false;
            }
            if(!name){
                alert("상호를 적어주세요");
                return false;
            }
            if(!latitude){
                alert("주소를 적어주세요");
                return false;
            }
           
            const formData = new FormData();

            formData.append('name', fairName);
            formData.append('code', fairCode);
            formData.append('isVisible', fairShow);
            formData.append('startDate', fairStart);
            formData.append('endDate', fairEnd);
            formData.append('extraParameter', parameter);


            if(bannerImage){
                formData.append('startBanner', bannerImage);

                if(fairBannerUrl){
                    formData.append('startBannerURL', fairBannerUrl);
                }else{
                    alert("배너의 주소를 입력해주세요");
                    return false;
                }
            }
    
            fetch('https://yuram2.luvmom2020.com/api/fairlist', {
                method: 'POST',
                body: formData,
                redirect: 'follow',
                headers: {
                    'Authorization': `Bearer ${jwtValue}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode == 401){
                    alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
                    navigator("/yuramAdmin")
                }else{
                    alert("박람회가 등록되었습니다.")
                    handleLinkClick(fairCode);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.error('There was an error sending the fair data:', error);
                // 오류 처리
            });


            const postData = {};

            if (fairCode) postData.code = fairCode;
            if (latitude) postData.latitude = latitude;
            if (longitude) postData.longitude = longitude;
            if (name) postData.name = name;
            if (address || addressBuilding) postData.address = address + addressBuilding;
            if (email) postData.email = email;
            if (phone) postData.phone = phone;
            if (createdAt) postData.createdAt = createdAt;



            fetch('https://yuram2.luvmom2020.com/api/directions/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtValue}`
            },
            body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(data => {

            if(data.statusCode == 401){
                alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
                navigator("/yuramAdmin")
            }else{
                console.log(data)
                window.location.reload();
            }

            })
            .catch(error => console.error('Error:', error));

    }
    // 박람회 생성했을때 이벤트
    const handleLinkClick = (element) => {
        localStorage.setItem('selectedCity', element); //선택한 상태로 변경
        navigator("/main") //메인으로 이동
    }
    // 박람회 수정했을때 이벤트
    const handleLinkClick2 = (element) => {
        localStorage.setItem('selectedCity', element); //선택한 상태로 변경
    }
      
    function handleFairNameChange(event) {
        const name = event.target.value;
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        //const fairC = "메세유람_사전등록_" + name;
        const fairC = "메세유람_사전등록_" + name + year + month;

        setFairName(event.target.value);
        if(name == "" || name== null){
            setFairCode();
        }else{
            setFairCode(fairC);
        }
    }
    
    function handleCodeFairChange(event) {
        setFairCode(event.target.value);
    }

    function toLocalISOString(date) {
        const off = date.getTimezoneOffset();
        const absoff = Math.abs(off);
        return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
    }

    function handleShowFairChange(event) {
        setFairShow(event.target.value);
    }

    function handleFairStartChange(event) {
        const date = new Date(event.target.value);
        const formate = toLocalISOString(date);
        setFairStart(formate);
    }

    function handleFairEndChange(event) {
        const date = new Date(event.target.value);
        const formate = toLocalISOString(date);
        setFairEnd(formate);
    }

    function handleFairUrlChange(event) {
        const imgUrl = event.target.value;
        setFairBannerUrl(imgUrl);
    }

    // 파일 선택 시 호출되는 함수
    const handleFileChange = (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile instanceof Blob) {
            setBannerImage(selectedFile);
            setLoading(false);
        } else {
            console.error('올바른 파일 형식을 선택해주세요.');
            setLoading(false);
        }
    }; 

    // 이미지를 삭제합니다
    const deleteImg = (fairId) => {

        if(window.confirm('팝업이미지를 삭제하시겠습니까?')){
            // API 엔드포인트 URL 설정
            const apiUrl = `https://yuram2.luvmom2020.com/api/fairlist/${fairId}/delete-image`;
            // DELETE 요청 보내기
            fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${jwtValue}`
                }
            })
            .then(response => {
                if(response.statusCode == 401){
                    alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
                    navigator("/yuramAdmin")
                  }else{
                    alert("이미지삭제완료")
                    window.location.reload();
                }
            })
            .catch(error => {
            // 네트워크 에러 등의 처리
            console.error('네트워크 에러:', error);
            });
        }
       
    };

    // 박람회 수정 
    function handleFairNameChangeUpdate(event) {
        const name = event.target.value;
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const fairC = "메세유람_사전등록_" + name + year + month;
        //const fairC = "메세유람_사전등록_" + name;

        setFairNameUpdate(event.target.value);
        if(name == "" || name== null){
            setFairCodeUpdate();
        }else{
            setFairCodeUpdate(fairC);
        }
    }

    function handleCodeFairChangeUpdate(event) {
        setFairCodeUpdate(event.target.value);
    }

    function handleShowFairChangeUpdate(value) {
        setFairShowUpdate(value); // 상태 업데이트
    }

    function handleFairStartChangeUpdate(event) {
        const date = new Date(event.target.value);
        const formate = toLocalISOString(date);
        setFairStartUpdate(formate);
    }

    function handleFairEndChangeUpdate(event) {
        const date = new Date(event.target.value);
        const formate = toLocalISOString(date);
        setFairEndUpdate(formate);
    }

    // 파일 선택 시 호출되는 함수
    const handleFileChangeUpdate = (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile instanceof Blob) {
            setBannerImageUpdate(selectedFile);
            setLoading(false);
        } else {
            console.error('올바른 파일 형식을 선택해주세요.');
            setLoading(false);
        }
    }; 

    // 파일 삭제 시 호출되는 함수
    const handleDelete = (fairId) => {
        setBannerImageUpdate();
        setFairIdUpdate(fairId);

    };
     // 박람회를 삭제할 준비를 합니다.
    const fairDelete = (fairId) => {
        setFairIdToDelete(fairId);
        setFairIdUpdate(fairId);
        setShowConfirmation(true);
    };
    // 박람회 삭제 모달창에서 확인눌렀을때
    const handleConfirmDelete = () => {
        console.log(fairIdToDelete)
        if (fairIdToDelete !== null) {
            // 삭제 로직 실행(fetch 등)
            const apiUrl = `https://yuram2.luvmom2020.com/api/fairlist/${fairIdToDelete}`;
            console.log(apiUrl, "박람회삭제")
            fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtValue}`
                // 필요한 경우 인증 토큰 등의 헤더 추가
                },
            })
            .then(response => {
            if (response) {
                if(response.statusCode == 401){
                    alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
                    navigator("/yuramAdmin")
                  }else{
                    alert('박람회를 삭제했습니다');
                    window.location.reload();
                  }
            } else {
                console.error('fairId');
            }
            })
            .catch(error => {
            // 네트워크 에러 등의 처리
            console.error('네트워크 에러:', error);
            });

            // 삭제가 완료된 후
            
            // 삭제 후에 상태 초기화 등 필요한 작업 수행
        }
        window.location.reload();
        setShowConfirmation(false);
        setFairIdToDelete(null);
    };

    const handleCancelDelete = () => {
        setShowConfirmation(false);
        setFairIdToDelete(null);
    };


    // 박람회를 수정합니다
    const fairUpdate = () => {
        // API 엔드포인트 URL 설정
        const apiUrl = `https://yuram2.luvmom2020.com/api/fairlist/${fairIdUpdate}`;
        console.log(apiUrl)
        function toLocalISOString(date) {
            const off = date.getTimezoneOffset();
            const absoff = Math.abs(off);
            return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
        }

        const formData = new FormData();
        let startDateToAdd;
        let EndDateToAdd;

        if (fairStartUpdate.includes('Z')) {
            // toLocalISOString 함수를 사용하여 날짜를 변환
            startDateToAdd = toLocalISOString(new Date(fairStartUpdate));
        } else {
            // fairStartUpdate 값을 그대로 사용
            startDateToAdd = fairStartUpdate;
        }

        if (fairEndUpdate.includes('Z')) {
            // toLocalISOString 함수를 사용하여 날짜를 변환
            EndDateToAdd = toLocalISOString(new Date(fairEndUpdate));
        } else {
            // fairStartUpdate 값을 그대로 사용
            EndDateToAdd = fairEndUpdate;
        }
        formData.append('name', fairNameUpdate);
        formData.append('code', fairCodeUpdate);
        formData.append('isVisible', fairShowUpdate);
        formData.append('startDate', startDateToAdd);
        formData.append('endDate', EndDateToAdd);



        if(!bannerImageUpdate){

        }else{
            formData.append('startBanner', bannerImageUpdate);
        }
        formData.append('extraParameter', parameterUpdate);

        if(fairBannerUrlUpdate){
            formData.append('startBannerURL', fairBannerUrlUpdate);
        }
        

        // PUT 요청 보내기
        fetch(apiUrl, {
            method: 'PUT',
            body: formData,
            headers: {
                'Authorization': `Bearer ${jwtValue}`
            }
        })
        .then(response => {

            if(response.statusCode == 401){
                alert("로그인이 만료되었습니다. 다시 로그인해 주세요");
                navigator("/yuramAdmin")
            }else{
                alert('박람회를 수정했습니다');
                handleLinkClick2(fairCodeUpdate);
                window.location.reload();
            }
         
        })
        .catch(error => {
            // 네트워크 에러 등의 처리
            console.error('네트워크 에러:', error);
        });
    };

    return (
      <>
      {/* <main className='selectPlaceWrap'> */}
      <main className='fairCreate_wrap'>
        <div className='tit'>박람회등록하기 (등록후 사전등록 테스트필수)</div>
        <div className='fair_setting_div'>
            <button type='button' onClick={()=>{setManual(!manual)}}>메뉴얼 보기</button>
           
             
            <table>
                <tbody>
                    <tr>
                        <td className='fair_setting_tit'>지역이름 <span className='required'>*</span></td>
                        <td><input type='text' onChange={handleFairNameChange} placeholder='ex)서울'></input> {manual &&  (<div className='manual_txt'>한글로 적어주세요(숫자x)</div>)}</td>
                    </tr>
                    <tr>
                        {/* <td className='fair_setting_tit'>판별코드</td> */}
                        <td><input type='text' value={fairCode} onChange={handleCodeFairChange} hidden ></input></td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>노출 여부<span className='required'>*</span></td>
                        <td>
                        <label>
                            <input className='margin_bottom_10' type="radio" name='showFair' value="노출" onChange={handleShowFairChange} />
                            노출
                        </label>
                        <label>
                            <input type="radio" name='showFair' value="미노출" onChange={handleShowFairChange} />
                            미노출
                        </label>
                        {manual &&  (<div className='manual_txt'>박람회를 노출로 생성해야 메인페이지에 나옵니다</div>)}
                        </td>
                        
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>시작일<span className='required'>*</span></td>
                        <td><input className="datetime" type='date' onChange={handleFairStartChange} format="yyyy-MM-ddTHH:mm"></input>
                        {manual &&  (<div className='manual_txt'>박람회 시작일을 선택해주세요</div>)}
                        
                        </td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>종료일<span className='required'>*</span></td>
                        <td><input className="datetime" type='date' onChange={handleFairEndChange} ></input>
                        {manual &&  (<div className='manual_txt'>박람회 종료일을 선택해주세요</div>)}
                        
                        </td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>팝업 배너 이미지</td>
                        <td>
                            <input className='margin_bottom_10' type='file' onChange={handleFileChange} />
                            
                            {manual &&  (<div className='manual_txt'>필수x , 해당 박람회의 팝업 배너를 설정합니다</div>)}
                            {/* 파일 미리보기 부분 */}
                            {loading ? (
                                <p>Loading...</p>
                            ) : bannerImage ? (
                                <div className='upload_img_wrap'>
                                    <img src={URL.createObjectURL(bannerImage)} alt="Banner Preview" style={{ width: '100px', height: 'auto' }} />
                                    {/* 파일 삭제 버튼 */}
                                    <button className="delete_btn" type="button" onClick={handleDelete}>삭제</button>
                                </div>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td className='fair_setting_tit'>팝업 링크</td>
                        <td>
                        <input className="datetime" type='text' onChange={handleFairUrlChange} ></input>
                            
                        {manual &&  (<div className='manual_txt'>팝업의 링크를 설정합니다</div>)}

                        </td>
                    </tr>
                    
                    <tr>
                                      <td className='fair_setting_tit'>상호입력<span className='required'>*</span></td>
                                      <td><input type="text" onChange={(e)=>setName(e.target.value)} placeholder='유람 베이비페어 in 광주 멋진운동장' style={{width:"90%"}}></input>
                                            {manual &&  (<div className='manual_txt'>상호를 입력합니다 ex) 유람 베이비페어 in 광주</div>)}
                                      </td>
                                      
                                    </tr>
                                    
                                    
                                    <tr>
                                      <td className='fair_setting_tit'>주소입력<span className='required'>*</span></td>
                                      <td>
                                        <div className="flex" style={{justifyContent:"flex-start"}}>
                                          <input type="hidden" name="place" />
                                          <input
                                            type="text"
                                            size="40"
                                            name="address"
                                            id="address"
                                            className="essential"
                                            value={address}
                                            onChange={(e)=>setAddress(e.target.value)}
                                            readOnly
                                            style={{"background" : "#f1f1f1"}}
                                            placeholder='주소검색'
                                          />
                                          <input
                                            type="hidden"
                                            size="40"
                                            name="addressBuilding"
                                            id="addressBuilding"
                                            className="essential"
                                            readOnly
                                            value={addressBuilding}
                                          />
                                          <input type="hidden" name="lat" placeholder="위도" value={latitude} onChange={(e)=>setLatitude(e.target.value)}/>
                                          <input type="hidden" name="lng" placeholder="경도" value={longitude} onChange={(e)=>setLongitude(e.target.value)}/>
                                            <button onClick={() => setVisible(true)} type='button'>주소검색</button>
                                          <button type="button" id="reset"  onClick={()=>{setAddress(null)}}>
                                            초기화
                                          </button>
                                        </div>
                                        
                                        {manual &&  (<div className='manual_txt'>해당 박람회의 정확한 주소를 입력합니다. 오시는길 페이지에 반영됩니다</div>)}

                                        <div className="regi_div">
                                            <div className='flex'>
                                            {visible &&
                                                <div style={addressStyle}>
                                                <button title="닫기" onClick={() => setVisible(false)}>닫기</button>
                                                <DaumPostcode
                                                    onComplete={handleComplete}
                                                />
                                                </div>
                                            }
                                            </div>
                                        </div>
                                     

                                      </td>
                                    </tr>
                    <tr>
                        {/* <td className='fair_setting_tit'>추가파라미터</td> */}
                        <td><input type='hidden' value={parameter}></input></td>
                    </tr>
                </tbody>
            </table>
            <button className="create_btn" type='button' onClick={()=>{submitFairCreate()}}>박람회 등록</button>


            { manual ? (
                <>
                <div className='manual_wrap example_img'>
                <div className='manual_title'>메뉴얼1 생성하기</div>  
                <p className='manual_txt'>1. 박람회를 생성합니다</p> <br/><br/>
                <p className='manual_txt'>2. 사전등록을 하여 알림톡이 잘오는지 확인 합니다</p> <br/><br/>
                <p className='manual_txt'>3. 박람회 메인/소개/방문팁/참가브랜드/오시는길/....등 페이지를 설정합니다</p> <br/><br/>

                {/* <p className='manual_txt'>**알림톡과 사전등록기능이 정상적으로 동작하려면 지역이름, 시작일, 종료일, 주소가 꼭 있어야합니다!</p> <br/><br/>
                <p className='manual_txt'>만약 지역이름을 수정할시 오시는길의 설정을 필수로 하셔야합니다 (알림톡 문구가 결정됨)</p> <br/><br/>
                <p className='manual_txt'></p><br/> */}
                <div className='flex'>
                    <div>메인진입화면<img src={main_ex}></img> </div>
                    <div>오시는길<img src={visit}></img> </div>
                    <div>메인팝업<img src={main_popup}></img> </div>
                </div>
                <br/>

               
                </div>
                </>

            ) : (
                null
            )
        }
        </div>

        { manual ? (
                <>
                <div className='manual_wrap example_img'>
                <div className='manual_title'>메뉴얼2 관리하기 </div>  
                <div className='' style={{width:"800px"}}>
                    <div>수정버튼위치<img src={edit_tip}></img> </div><br/>
                    <div>나머지 사항<img src={edit_tip2}></img> </div>
                </div>
               
                <p className='manual_txt'> - 지역이름/팝업이미지/시작일/종료일등은 수정버튼을 눌러 수정할수 있습니다</p> <br/><br/>
                <p className='manual_txt'>-나머지 
                    메인설정/
                    소개설정/	
                    방문팁/
                    참가브랜드/
                    오시는길/
                    박람회이벤트/
                    브랜드이벤트/
                    공지사항 설정은 각 페이지에서 수정할수있습니다
                </p> <br/><br/>
                </div>
                </>

            ) : (
                null
            )
        }
        <div className='fair_setting_wrap'>
            <div className='tit'>박람회 관리</div>
            <div className='fair_table_wrap'>
                <table>
                    <thead>
                        <tr>
                            {/* <th>ID</th> */}
                            <th>이름</th>
                            <th>박람회이름</th>
                            <th>팝업이미지</th>
                            {/* <th>생성일</th> */}
                            <th>시작일</th>
                            <th>마감일</th>
                            {/* <th>최근변경일</th> */}
                            <th>노출여부</th>
                            <th className='b1'>메인설정</th>
                            <th className='b2'>소개설정</th>

                            <th className='b3'>방문팁<br/>설정</th>
                            <th className='b4'>참가브랜드<br/>설정</th>
                            <th className='b5'>오시는길<br/>설정</th>

                            <th className='b6'>박람회이벤트<br/>설정</th>
                            <th className='b7'>브랜드이벤트<br/>설정</th>

                            <th className='b8'>공지사항<br/>설정</th>

                            <th className='b9'>박람회수정</th>
                            <th className='b10'>박람회삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getFairData.length > 0 && getFairData.map((fair) => (
                            <tr key={fair.id}>
                                {/* <td>{fair.id}</td> */}
                                <td>{fair.name}</td>
                                <td>{fair.code}</td>
                                <td className='fair_img_div'>
                                    <img src={fair.startBanner} onClick={() => openModal(fair.startBanner)}></img> <br/>
                                    <button className="delete_btn" type="button" onClick={()=>{deleteImg(fair.id)}}>사진삭제</button>
                                </td>
                                {/* <td>{new Date(fair.createdAt).toLocaleString('ko-KR')}</td> */}
                                <td>{new Date(fair.startDate).toLocaleString('ko-KR')}</td>
                                <td>{new Date(fair.endDate).toLocaleString('ko-KR')}</td>
                                {/* <td>{new Date(fair.updatedAt).toLocaleString('ko-KR')}</td> */}
                                <td>{fair.isVisible}</td>
                                <td className='b1'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/main")}}>설정</button></td>
                                <td className='b2'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/introduce")}}>설정</button></td>
                                <td className='b3'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/visitTip")}}>설정</button></td>
                                <td className='b4'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/brandList")}}>설정</button></td>
                                <td className='b5'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/visit")}}>설정</button></td>
                                <td className='b6'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/payBack")}}>설정</button></td>
                                <td className='b7'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/brandEvent")}}>설정</button></td>
                                <td className='b8'><button type='button' onClick={()=>{localStorage.setItem('selectedCity', fair.code);navigator("/notice")}}>설정</button></td>
                                <td className='b9'><button type='button' onClick={()=>openUpdateModal(fair.id,fair.name,fair.code,fair.startDate,fair.endDate,fair.startBanner,fair.isVisible,fair.extraParameter,fair.startBannerURL)}>수정</button></td>
                                <td className='b10'><button type='button' onClick={()=>fairDelete(fair.id)}>삭제</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
       
        
        {/* 이미지 Modal */}
        {showModal && (
            <div className='modal'>
            <div className='modal-content_mini'>
                <span className='close' onClick={closeModal}>&times;</span>
                <img src={modalImage} alt='Modal Image' className='modal-image' style={{display:"block", width: "100%"}}/>
            </div>
            </div>
        )}

        {/* 수정 Modal */}
        {showUpdateModal && (

            <FairUpdateModal
            closeUpdateModal={() => setShowUpdateModal(false)}
            // 필요한 props 전달

            fairIdUpdate={fairIdUpdate}
            fairNameUpdate={fairNameUpdate}
            fairCodeUpdate={fairCodeUpdate}
            fairShowUpdate={fairShowUpdate}
            fairStartUpdate={fairStartUpdate}
            fairEndUpdate={fairEndUpdate}
            bannerImageUpdate={bannerImageUpdate}
            showUpdateModal={showUpdateModal}
            parameterUpdate={parameterUpdate}
            fairBannerUrlUpdate={fairBannerUrlUpdate}

            handleFairNameChangeUpdate={handleFairNameChangeUpdate}
            handleCodeFairChangeUpdate={handleCodeFairChangeUpdate}
            handleShowFairChange={handleShowFairChange}
            handleFairStartChangeUpdate={handleFairStartChangeUpdate}
            handleFairEndChangeUpdate={handleFairEndChangeUpdate}
            handleFileChangeUpdate={handleFileChangeUpdate}
            handleShowFairChangeUpdate={handleShowFairChangeUpdate}
            loading={loading}
            bannerImage={bannerImage}
            handleDelete={handleDelete}
            parameter={parameter}
            fairUpdate={fairUpdate}
            deleteImg={() => deleteImg(fairIdUpdate)}
            setFairBannerUrlUpdate={setFairBannerUrlUpdate}
            />
         
        )}

        {/* 모달(확인 창) */}
        {showConfirmation && (
            <div className="modal">
                <div className="modal-content_mini">
                    <p>박람회를 삭제하시겠습니까?</p>
                    <button type="button" onClick={handleConfirmDelete}>확인</button>
                    <button className="delete_btn" type="button" onClick={handleCancelDelete}>취소</button>
                </div>
            </div>
        )}
      </main>
       
      </>
    );
}

export default FairCreate;