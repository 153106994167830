//회원가입
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import MiniNavi from "../components/Common/MiniNavi.js"
import Navi from "../components/Common/Navi.js";
import main_img from '../static/img/register/main_login.png';
import more_btn from "../static/img/register/more.svg";
//css
import "../static/css/register/register.css";
import PopUp from '../components/Common/PopUp.js';
// 1. sns 로그인 페이지
// 2. sns 에서 가져온 값이 있으면 회원가입을 진행해야합니다
// 3. 회원가입 완료하면 localstorage 에서 세션 값을 저장시켜섯 자동로그인 처럼 만들어야하는점


function MemberRenewal() {
  const wherePath = "메세유람_사전등록_대구202403";
  const navi_name = "사전등록";
  const whereAlim = "대구";
  const whereDate = "06.27(목) ~ 06.30(일)";
  const whereLocation = "계명문화대학교 수련관";

    const [city, setCity] = useState(wherePath);
    localStorage.setItem('selectedCity', wherePath);
    const navigator = useNavigate();
   // Define state variables to store form values
    const [name, setName] = useState('');
    const [gender, setGender] = useState(''); // You can use a string or a boolean based on your needs
    const [contact, setContact] = useState('');
    const [location, setLocation] = useState('');
    const [district, setDistrict] = useState('');
    const [children, setChildren] = useState([]);
    //시/도
    const [citys, setCitys] = useState([]);
    const [region, setRegion] = useState([]);
    const [agreeChkBox ,setAgreeChkBox] = useState(true);
    const [modalContent, setModalContent] = useState(null)
    // 가입경로
    const [path, setPath] = useState(wherePath);

    const api_url = 'https://yuram3.luvmom2020.com/api/v1/';

    //알림톡 추가 정보
    const [alimTalkAddress,setAlimTalkAddress] = useState(null);
    const [alimTalkCode,setAlimTalkCode] = useState(null);
    const [alimDate,setAlimDate] = useState(null);

    //시도가져오기
    useEffect(() => {
      fetch(`${api_url}accounts/city_list`)
        .then((response) => {
          if (!response.ok) {
          }
          return response.json();
        })
        .then((data) => {
          setCitys(data.region)
        })
        .catch((error) => {
        });

        // 오시는길 장소와 주소를 보내기 위해 이렇게 합니다!
        // if(city){
        //   fetch(`https://yuram2.luvmom2020.com/api/directions/${city}`, {
        //     method: 'GET',
        //       headers: {
        //         'Content-Type': 'application/json'
        //       }
        //     })
        //   .then(response => response.json())
        //   .then(data => {
        //     if( data ){
        //         //여기서 code 와 address 를 가져와야해요
        //         setAlimTalkAddress(data.address)
        //         if(data.address == null || data.address == ""){
        //           alert("사전등록 준비중입니다!")
        //         }
        //     }
        //   })
        //   .catch(error => console.error('Error:', error));
        // }

        if(city){
          fetch('https://yuram2.luvmom2020.com/api/fairlist/list', {

        })
        .then((response) => response.json())
        .then((result) => {
            if(result.length > 0){
              const foundItem = result.find(item => item.code === city);
                if(foundItem.startDate){
                  setAlimTalkCode(foundItem.name)
                  setAlimDate(`${formatDate(foundItem.startDate)} ~ ${formatDate(foundItem.endDate)}`)
                }else{
                  alert("미노출 박람회 입니다. 다른 사전등록을 이용해주세요")
                }
            }else{
            }
        })
        .catch((error) => {
          // alert("미노출 박람회 입니다. 다른 사전등록을 이용해주세요")
          // navigator("/")
        });
        }

    }, []);


    function formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: '2-digit', day: '2-digit', weekday: 'long' };
      const formatter = new Intl.DateTimeFormat('ko-KR', options);
      const formattedDate = formatter.format(date);

      // 현재 표시되고 있는 요일을 한글로 변환
      const dayOfWeek = formatter.formatToParts(date).find(part => part.type === 'weekday').value;
      const convertedDayOfWeek = dayOfWeek.replace('요일', ''); // "일요일"에서 "일"만 추출

      // 변환된 요일을 포함하여 최종 결과 생성
      const finalResult = formattedDate.replace(dayOfWeek, `(${convertedDayOfWeek})`);
      return finalResult;
    }


    // Event handlers to update state when input values change
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleGenderChange = (e) => {
        setGender(e.target.id); // You can use the id to distinguish between 'male' and 'female'
    };

    const handleContactChange = (e) => {
        setContact(e.target.value);
    };

    const handleLocationChange = (e) => {
        setLocation(e.target.value);

    };
    useEffect(() => {
        //군구데이터가져오기
          fetch(`${api_url}accounts/city_filter?city_1=${location}`)
          .then((response) => {
            if (!response.ok) {
            }
            return response.json();
          })
          .then((data) => {
            setRegion(data.data)
          })
          .catch((error) => {
          });
    }, [location]);

    const handleDistrictChange = (e) => {
        setDistrict(e.target.value);
    };

    const handleChildrenChange = (e) => {
        const selectedValue = e.target.value;
        // Check if the selected value is already in the array, and either add it or remove it accordingly
        if (children.includes(selectedValue)) {
          setChildren(children.filter((value) => value !== selectedValue));
        } else {
          setChildren([...children, selectedValue]);
        }
    };

    // 체크박스 상태를 토글하는 함수
    const toggleCheckbox = (id) => {
      const updatedCheckboxes = checkboxes.map((checkbox) => {
        if (checkbox.id === id) {
          return { ...checkbox, checked: !checkbox.checked };
        }
        return checkbox;
      });
      setCheckboxes(updatedCheckboxes);
    };


    const initialCheckboxes = [
      { id: 'chk_join_terms_service', name: 'chk_join_terms_service', checked: true, required: true, label: '(필수) 이용약관 동의' ,
      content:`
      개인정보취급방침

\n메쎄유람(이하 '회사'라 합니다.)은 회원의 사생활 및 개인정보를 적극적으로 보호하여 정보화 사회에서의 통신의 자유를 보장하고자 아래와 같이 개인정보취급방침을 명시하여 실천하고 있습니다. 온라인상에서의 이용자 여러분의 개인정보 보호 문제를 아주 중요하게 생각하고 있습니다. 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
\n
\n가. 수집하는 개인정보의 항목 및 수집방법
\n나. 개인정보 수집 및 이용목적
\n다. 개인정보의 보유 및 이용 기간
\n
\n▶수집하는 개인정보의 항목 및 수집방법
\n(1) 수집하는 개인정보의 항목
\n'베이비페어, 럽맘, 펫쇼 등' 행사 사무국은 원활한 행사진행을 위하여 아래와 같은 개인정보를 수집하고 있습니다. (이름, 전화번호, 간략한 주소, 부가 정보) 또한 다양한 서비스를 원하는 회원에 한하여 아래와 같은 개인정보를 선택적으로 수집을 하고 있습니다.
\n
\n(2) 개인정보 수집방법
\n'베이비페어, 럽맘, 펫쇼 등' 홈페이지 이용자가 자발적으로, 구체적으로 기입할 때만 개인정보를 수집하고 있습니다.
\n
\n▶개인정보 수집 및 이용목적
\n'베이비페어, 럽맘, 펫쇼 등'는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
\n(1) 회원관리
\n회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인
\n
\n(2) 제품 홍보 및 광고 서비스 동의
\nSMS 문자서비스를 이용한 메쎄유람의 주관 박람회 홍보 광고 및 행사일정의 안내 등 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고게재, 이벤트 및 광고성 정보 제공에 동의(이 경우 관심을 갖는 유형에 대한 총체적 정보만 제공될 뿐, 개인의 구체적인 정보는 제공되지는 않음)
\n
\n▶개인정보의 보유 및 이용기간
\n'베이비페어, 럽맘, 펫쇼 등'의 개인정보 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 2년이 지나면 해당 개인정보를 지체 없이 파기합니다.
\n
\n▶마케팅 수신동의 관련
\n정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 50조 1항에 따라 메쎄유람의 박람회 광고 및 행사일정의 안내 등 광고성 정보를 전달하기 위하여 개인정보를 수집 및 마케팅에 이용에 동의
\n(본 약관내에는 영리목적 광고성 정보 수신동의에 관한 사항이 포함되어 있습니다)
\n
\n※ 개인정보의 취급위탁을 위한 제3자 제공 관리
\n<위탁업무의 내용>
\n
\n■ 럽맘 : 전시장 입장 데이터 및 서버관리, 데이터 가공 업무, 알림톡 발송 서비스
\n■ 알리고 : 문자 및 알림톡 발송 서비스
\n
\n가. 회사는 이용자들의 개인정보를 「개인정보 수집·이용 항목 및 목적」에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
\n① 이용자들이 사전에 공개 또는 제3자 제공에 동의한 경우
\n② 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사관 및 감독당국의 요구가 있는 경우
\n
\n나. 회사는 원활하고 향상된 서비스를 위하여 개인정보 취급을 타인에게 위탁할 수 있습니다.
\n다. 위탁 처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.
\n라. 관계법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 개인정보보호 관련 법규의 준수, 개인정보에 관한 비밀유지, 제3자 제공에 대한 금지, 사고시의 책임 부담, 위탁기간, 처리 종료 후의 개인정보의 반환 또는 파기 의무 등을 규정하고, 이를 준수하도록 관리하고 있습니다.
\n마. 아래의 경우 참가업체에게 정보가 제공되며 이는 해당 참가업체의 회사 및 제품안내 이외의 용도로는 사용되지 않습니다. 제공에 대한 선택권은 참관객에게 있습니다.
\n① 본 행사 참가업체의 홈페이지에 게재된 온라인 배너를 통해 전시회 사전등록이 이뤄진 경우 신청 당사자의 개인정보가 해당 참가사에 제공 될 수도 있습니다.
      \n
      `},

     ];
   const openModal = (content) => {
       const formattedContent = content.split('\n').map((line, index) => (
           <div key={index}>
               {line}
               <br/>
           </div>
       ));
       setModalContent(
            <div style={{"textAlign":"left"}}>{formattedContent}</div>
        );
    };

    const closeModal = () => {
        setModalContent(null);
    };

    const [isChecked, setIsChecked] = useState(true);
    // 체크박스 상태를 토글하는 함수
    const AlltoggleCheckbox = () => {
      const updatedCheckboxes = checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: !isChecked,
      }));
      setCheckboxes(updatedCheckboxes);
      setIsChecked(!isChecked);
    };


    const formData = {
      name: name,
      phone: contact,
      gender: gender,
      city: wherePath,
      addr1: location,
      addr2: district,
      children: children,
      alim_city : whereAlim,
      alim_date : whereDate,
      alim_location : whereLocation,
    }
    // 체크박스 상태를 토글하는 함수
    const [checkboxes, setCheckboxes] = useState(initialCheckboxes);
    // 서버에 보낼 데이터
    const postData = JSON.stringify(formData);

    const handleSubmit = () => {
        if(!name){
            alert("이름을 입력해주세요")
            return false;
        }
        if(!gender){
            alert("성별을 선택해주세요")
            return false;
        }
        if(!contact){
            alert("연락처를 입력해주세요")
            return false;
        }
        if(!location){
            alert("지역을 입력해주세요")
            return false;
        }
        if(!district){
          alert("군/구를 입력해주세요")
          return false;
        }


      const requiredCheckboxes = checkboxes.filter((checkbox) => checkbox.required && !checkbox.checked);
        if (requiredCheckboxes.length === 0) {
        } else {
          alert('모든 필수 항목에 동의해주세요.');
          return false;
        }


         //api_url
    fetch(`${api_url}accounts/sign/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: postData,
    })
     .then((response) => {
        if (!response.ok) {
          throw new Error('HTTP Error ' + response.status);
        }
        return response.json();
      })
      .then((data) => {
        // 서버 응답 처리 코드를 작성합니다.
        // 사전등록완료 알림톡api 호출
        if(data.msg  == 'success' ){
              localStorage.setItem('registerdone', path);
              alert('사전등록이 완료 되었습니다')
              navigator('/main');
        }else{
            localStorage.setItem('registerdone', path);
            alert('이미 사전등록이 완료되었습니다')
            navigator('/main');
        }

      })
      .catch((error) => {
        console.error('오류 발생:', error);
        if (error.response && error.response.status === 400) {
            alert('이미 사전등록이 완료되었습니다')
             navigator('/main');
        }else if (error.message === 'Failed to fetch') {
            alert('이미 사전등록이 완료되었습니다')
             navigator('/main');
        }else{
            alert('이미 사전등록이 완료되었습니다')
             navigator('/main');
        }
      });
      };



    // 사전신청을 완료 했는지
    const [applyDone, setApplyDone] = useState(false);
    //선택없으면 모달창 뜨게
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    useEffect(() => {
        //scrollToTop();
        if (isPopupOpen) {
          // 팝업이 열렸을 때 body 스크롤을 막음
          document.body.style.overflow = 'hidden';
        } else {
          // 팝업이 닫혔을 때 body 스크롤을 원래대로 되돌림
          document.body.style.overflow = 'auto';
        }

        // 컴포넌트 언마운트 시 스타일 초기화
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [isPopupOpen]);

    useEffect(()=>{
        //가입경로를 가져옵니다
        let storedCity;
        if(city == "" || city == null){
            // 없으면 모달창뜨게
            setPopupOpen(true);
        }else{
            storedCity = city;
        }
        setPath(storedCity);

        const registerDoneValue = localStorage.getItem('registerdone');
        if (registerDoneValue ==city){
            // 해당도시의 사전등록이 되었으면
            setApplyDone(true);
        }
    })

    return (
        <>
        <div className='wrapper'>
        <Navi/>
        <main className='detail_cont_wrap'>
           <MiniNavi navi_name={navi_name}/>

           <div className="Member">

            {applyDone ?(
                <div className="applyDone_wrap" >
                    <img src={main_img}/>
                    {city}의 사전신청 완료되었습니다!
                </div>
            ):(
                <>
                <div className="now_step">
                    사전등록하기
                </div>
                <div className='register_wrap'>
                    <div className='regist_tit'>성함</div>
                    <div><input type='text' placeholder='성함' value={name} onChange={handleNameChange}></input></div>

                </div>
                <div className='register_wrap'>
                    <div className='regist_tit'>성별</div>
                    <div className='select_box'>
                        <div className='select_box_item'>
                            <input type="radio" name='gender' id="M" checked={ gender=== 'M'} onChange={handleGenderChange}></input>
                            <label htmlFor="M">남</label>
                        </div>
                        <div className='select_box_item'>
                            <input type="radio" name='gender' id="F" checked={gender === 'F'} onChange={handleGenderChange}></input>
                            <label htmlFor="F">여</label>
                        </div>
                    </div>
                </div>
                <div className='register_wrap'>
                    <div className='regist_tit'>연락처</div>
                    <div><input type='text' maxLength={11} placeholder='-제외하고입력해주세요' onChange={handleContactChange}></input></div>
                </div>
                <div className='register_wrap'>
                    <div className='regist_tit'>지역</div>
                    <div className='flex'>
                        <select onChange={handleLocationChange}>
                          <option value="">시/도를 선택해주세요</option>
                          {citys && citys.map((item, index) => (
                            <option key={index} value={item.city_1}>
                              {item.city_1}
                            </option>
                          ))}
                        </select>
                        <select onChange={handleDistrictChange}>
                        <option value="">군/구를 선택해주세요</option>
                          {region && region.map((item, index) => (
                            <option key={index} value={item.city_2}
                            >
                              {item.city_2}
                            </option>
                          ))}
                        </select>
                    </div>
                </div>
                <div className='register_wrap'>
                <div className="register_wrap">
                <div className="regist_tit">자녀여부</div>
                <div className="select_box">
          <div className="select_box_item">
            <input
              id="pregnant"
              type="checkbox"
              name="children"
              value="임산부"
              checked={children.includes('임산부')}
              onChange={handleChildrenChange}
            />
            <label htmlFor="pregnant">임산부</label>
          </div>
          <div className="select_box_item">
            <input
              id="pre12"
              type="checkbox"
              name="children"
              value="1~12개월"
              checked={children.includes('1~12개월')}
              onChange={handleChildrenChange}
            />
            <label htmlFor="pre12">1~12개월</label>
          </div>
          <div className="select_box_item">
            <input
              id="pre13"
              type="checkbox"
              name="children"
              value="13~24개월"
              checked={children.includes('13~24개월')}
              onChange={handleChildrenChange}
            />
            <label htmlFor="pre13">13~24개월</label>
          </div>
          <div className="select_box_item">
            <input
              id="pre25"
              type="checkbox"
              name="children"
              value="25~36개월"
              checked={children.includes('25~36개월')}
              onChange={handleChildrenChange}
            />
            <label htmlFor="pre25">25~36개월</label>
          </div>
          <div className="select_box_item">
            <input
              id="pre36"
              type="checkbox"
              name="children"
              value="36개월이상"
              checked={children.includes('36개월이상')}
              onChange={handleChildrenChange}
            />
            <label htmlFor="pre36">36개월이상</label>
          </div>
                </div>
                </div>
                </div>
                <div className='register_wrap'>
                <div className="div_join_all_ok">
                    <ul className="bo_v_option">
                        <li className="terms_chk_box all_chk_box">
                            <input type="checkbox" id="notice" name="notice" className="selec_chk" onChange={AlltoggleCheckbox} value="1" checked={isChecked}/>
                            <label htmlFor="notice">약관 전체 동의</label>
                        </li>
                        <li>
                            <ul className="bo_v_option">
                                {checkboxes.map((checkbox) => (
                                    <li key={checkbox.id} className="terms_chk_box">
                                    <input
                                        type="checkbox"
                                        id={checkbox.id}
                                        name={checkbox.name}
                                        className="selec_chk"
                                        onChange={() => toggleCheckbox(checkbox.id)}
                                        checked={checkbox.checked}
                                    />
                                    <label htmlFor={checkbox.id}>{checkbox.label}</label>
                                    {checkbox.content ? (
                                    <div onClick={() => openModal(checkbox.content)}><img src={more_btn} alt="더보기"/></div>
                                    ) : null}
                                    </li>
                                ))}


                            </ul>


                        </li>
                    </ul>
                    </div>
                </div>
                <div>
                  <button className="register_button" onClick={handleSubmit}>사전등록신청</button>
                </div>
                </>
            )}
        </div>
        {modalContent && (
                <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div>{modalContent}</div>
                </div>
                </div>
            )}
        </main>
      </div>
        {isPopupOpen &&

      <PopUp
        isPopupOpen={isPopupOpen}
        setPopupOpen={setPopupOpen}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        setCity={setCity}
      />}
        </>

    );
}

export default MemberRenewal;